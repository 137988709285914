import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateNendCampaignInput,
  CreateNendCampaignMutation
} from "../__relay_artifacts__/CreateNendCampaignMutation.graphql";

const mutation = graphql`
  mutation CreateNendCampaignMutation($input: CreateNendCampaignInput!) {
    createNendCampaign(input: $input) {
      nendCampaignEdge {
        node {
          ...NendCampaignEditButton_nendCampaign
        }
      }
    }
  }
`;

let clientMutationId = 0;

const commit = (environment: Environment, input: CreateNendCampaignInput) =>
  commitMutation<CreateNendCampaignMutation>(environment, {
    mutation,
    variables: {
      input: {
        clientMutationId: `${clientMutationId++}`,
        ...input
      }
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.nendCampaignGroupId,
        connectionInfo: [
          {
            key: "NendCampaignListTableCard_nendCampaigns",
            rangeBehavior: "prepend"
          }
        ],
        edgeName: "nendCampaignEdge"
      }
    ]
  });

export default { commit };
