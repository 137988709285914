import {
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store
} from "relay-runtime";

import { FIREBASE_ID_TOKEN } from "./constants";

const fiveMinutes = 300 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: fiveMinutes });

export const fetchQuery = (
  operation: any,
  variables: any,
  cacheConfig: any
) => {
  // NOTE: relay network cache configs
  // see: https://facebook.github.io/relay/docs/en/network-layer#caching
  const queryID = operation.text;
  const isMutation = operation.operationKind === "mutation";
  const isQuery = operation.operationKind === "query";
  const forceFetch = cacheConfig && cacheConfig.force;

  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  return fetch(process.env.REACT_APP_RELAY_ENDPOINT as string, {
    method: "POST",
    body: JSON.stringify({
      query: operation.text,
      variables
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem(FIREBASE_ID_TOKEN)}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then((response: Response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      // Update cache on queries
      if (isQuery && json) cache.set(queryID, variables, json);
      // Clear cache on mutations
      if (isMutation) cache.clear();
      return json;
    });
};

export default new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource())
});
