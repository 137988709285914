import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ProjectHeader_project } from "../__relay_artifacts__/ProjectHeader_project.graphql";
import { ProjectHeader_viewer } from "../__relay_artifacts__/ProjectHeader_viewer.graphql";
import logo from "../logo_primary.svg";
import ProjectDrawerMenuList from "./ProjectDrawerMenuList";
import ProjectSelectionButton from "./ProjectSelectionButton";

type Props = {
  project: ProjectHeader_project;
  viewer: ProjectHeader_viewer;
};

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    width: DRAWER_WIDTH
  },
  appBar: {},
  logo: {
    width: 124
  },
  logoWrapper: {
    width: DRAWER_WIDTH
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar
}));

const useMobileOpen = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return { mobileOpen, handleDrawerToggle };
};

const ProjectHeader: React.FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  const { mobileOpen, handleDrawerToggle } = useMobileOpen();
  const menuList = <ProjectDrawerMenuList project={project} viewer={viewer} />;
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logoWrapper}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          <div className={classes.grow}>
            <ProjectSelectionButton
              key="project"
              project={project}
              viewer={viewer}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          {menuList}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />
          {menuList}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default createFragmentContainer(ProjectHeader, {
  viewer: graphql`
    fragment ProjectHeader_viewer on User {
      id
      ...AccountButton_viewer
      ...ProjectSelectionButton_viewer
      ...ProjectDrawerMenuList_viewer
    }
  `,
  project: graphql`
    fragment ProjectHeader_project on Project {
      ...ProjectSelectionButton_project
      ...ProjectDrawerMenuList_project
    }
  `
});
