/* tslint:disable */
/* eslint-disable */
/* @relayHash afe11ff62c3d165a0c4f2d71f75eae15 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateNendCampaignInput = {
    id: string;
    title: string;
    nendCampaignId?: string | null;
    clientMutationId?: string | null;
};
export type UpdateNendCampaignMutationVariables = {
    input: UpdateNendCampaignInput;
};
export type UpdateNendCampaignMutationResponse = {
    readonly updateNendCampaign: {
        readonly nendCampaign: {
            readonly " $fragmentRefs": FragmentRefs<"NendCampaignEditButton_nendCampaign">;
        } | null;
    } | null;
};
export type UpdateNendCampaignMutation = {
    readonly response: UpdateNendCampaignMutationResponse;
    readonly variables: UpdateNendCampaignMutationVariables;
};



/*
mutation UpdateNendCampaignMutation(
  $input: UpdateNendCampaignInput!
) {
  updateNendCampaign(input: $input) {
    nendCampaign {
      ...NendCampaignEditButton_nendCampaign
      id
    }
  }
}

fragment NendCampaignEditButton_nendCampaign on NendCampaign {
  ...NendCampaignEditForm_nendCampaign
}

fragment NendCampaignEditForm_nendCampaign on NendCampaign {
  id
  title
  nendCampaignId
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNendCampaignInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateNendCampaignMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendCampaignPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaign",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaign",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "NendCampaignEditButton_nendCampaign",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateNendCampaignMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendCampaignPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaign",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaign",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nendCampaignId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateNendCampaignMutation",
    "id": null,
    "text": "mutation UpdateNendCampaignMutation(\n  $input: UpdateNendCampaignInput!\n) {\n  updateNendCampaign(input: $input) {\n    nendCampaign {\n      ...NendCampaignEditButton_nendCampaign\n      id\n    }\n  }\n}\n\nfragment NendCampaignEditButton_nendCampaign on NendCampaign {\n  ...NendCampaignEditForm_nendCampaign\n}\n\nfragment NendCampaignEditForm_nendCampaign on NendCampaign {\n  id\n  title\n  nendCampaignId\n  createdAt\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ef21fd5d1dcf1b66f7ffcaac24c68df5';
export default node;
