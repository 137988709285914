/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembershipRole = "ADMIN" | "ADVERTISER" | "GUEST" | "PUBLISHER" | "%future added value";
export type MembershipRoleEditForm_membership = {
    readonly id: string;
    readonly role: MembershipRole;
    readonly " $refType": "MembershipRoleEditForm_membership";
};
export type MembershipRoleEditForm_membership$data = MembershipRoleEditForm_membership;
export type MembershipRoleEditForm_membership$key = {
    readonly " $data"?: MembershipRoleEditForm_membership$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipRoleEditForm_membership">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "MembershipRoleEditForm_membership",
  "type": "Membership",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'f06783ea35d17b7bf71b74ada2fe3674';
export default node;
