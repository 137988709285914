import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import * as yup from "yup";

import { extractValidationErrors } from "../lib/mutationUtils";

export interface FormValues {
  email: string;
  password: string;
}

type Props = {
  title: string;
  submitText: string;
  onSubmit: (values: FormValues) => Promise<any>;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },

  cardAction: {
    width: "100%"
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}));

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required()
});

const initialValues = {
  email: "",
  password: ""
};

const SignInForm: React.FC<Props> = ({ title, submitText, onSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitHandler = React.useCallback(
    async (values, { setErrors }) => {
      try {
        await onSubmit(values);
        enqueueSnackbar("ログインしました", { variant: "success" });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmit]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmitHandler}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card className={classes.card}>
            <CardHeader title={title} />
            <CardContent>
              <FormikTextField
                name="email"
                label="メールアドレス"
                required
                fullWidth
                margin="normal"
              />
              <FormikTextField
                name="password"
                label="パスワード"
                type="password"
                required
                margin="normal"
                fullWidth
              />
            </CardContent>
            <CardActions className={classes.cardAction}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                className={classes.submit}
              >
                {submitText}
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
