import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer } from "react-relay";
import { useParams } from "react-router";

import NendCampaignGroupForm, {
  FormValues,
  schema
} from "../components/NendCampaignGroupForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateNendCampaignGroupMutation from "../mutations/CreateNendCampaignGroupMutation";

type Props = {
  relay: RelayProp;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
  nendCampaignGroupId: null
};

const NendCampaignGroupCreateForm: React.FC<Props> = ({
  relay,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { promotionId } = useParams<{ promotionId: string }>();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ title, nendCampaignGroupId }, { setErrors }) => {
      try {
        const {
          createNendCampaignGroup
          // @ts-ignore
        } = await CreateNendCampaignGroupMutation.commit(relay.environment, {
          nendPromotionId: promotionId,
          title,
          nendCampaignGroupId
        });

        const campaignGroupEdge =
          createNendCampaignGroup &&
          createNendCampaignGroup.nendCampaignGroupEdge;
        if (!campaignGroupEdge) throw new Error("assertion failed");

        enqueueSnackbar("キャンペーングループを作成しました", {
          variant: "success"
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, promotionId, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendCampaignGroupForm
          {...formikHelpers}
          title="キャンペーングループの作成"
        />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendCampaignGroupCreateForm, {});
