/* tslint:disable */
/* eslint-disable */
/* @relayHash 278e8ecc9ab61e43626108e45a9f1da7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MeasureFormula = "AVG" | "INT_SUM" | "SUM" | "%future added value";
export type MeasureValue = "CLICK" | "CPC" | "CPI" | "CTR" | "CVR" | "DATE" | "GROSS_BUDGET_CONSUMPTION" | "ID" | "IMPRESSION" | "INSTALL" | "NAME" | "OS" | "%future added value";
export type MeasureInput = {
    value: MeasureValue;
    formula?: MeasureFormula | null;
};
export type FilterInput = {
    periodSince: string;
    periodTill: string;
    promotionIds: Array<string>;
};
export type OrderInput = {
    value: MeasureValue;
    isDesc?: boolean | null;
};
export type PromotionReportFormContainer_QueryVariables = {
    projectId: string;
    measures: Array<MeasureInput>;
    filter: FilterInput;
    orders?: Array<OrderInput> | null;
};
export type PromotionReportFormContainer_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"PromotionReportFormContainer_project">;
    } | null;
};
export type PromotionReportFormContainer_Query = {
    readonly response: PromotionReportFormContainer_QueryResponse;
    readonly variables: PromotionReportFormContainer_QueryVariables;
};



/*
query PromotionReportFormContainer_Query(
  $projectId: ID!
  $measures: [MeasureInput!]!
  $filter: FilterInput!
  $orders: [OrderInput!]
) {
  project(id: $projectId) {
    ...PromotionReportFormContainer_project
    id
  }
}

fragment AdvertiserReportList_project on Project {
  id
  report(measures: $measures, filter: $filter, orders: $orders) {
    measures
  }
}

fragment PromotionReportFormContainer_project on Project {
  id
  nendPromotions {
    edges {
      node {
        id
        title
      }
    }
  }
  ...AdvertiserReportList_project
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "measures",
    "type": "[MeasureInput!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "FilterInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orders",
    "type": "[OrderInput!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PromotionReportFormContainer_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PromotionReportFormContainer_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PromotionReportFormContainer_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotions",
            "storageKey": null,
            "args": null,
            "concreteType": "NendPromotionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NendPromotionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NendPromotion",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "report",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "measures",
                "variableName": "measures"
              },
              {
                "kind": "Variable",
                "name": "orders",
                "variableName": "orders"
              }
            ],
            "concreteType": "Report",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "measures",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PromotionReportFormContainer_Query",
    "id": null,
    "text": "query PromotionReportFormContainer_Query(\n  $projectId: ID!\n  $measures: [MeasureInput!]!\n  $filter: FilterInput!\n  $orders: [OrderInput!]\n) {\n  project(id: $projectId) {\n    ...PromotionReportFormContainer_project\n    id\n  }\n}\n\nfragment AdvertiserReportList_project on Project {\n  id\n  report(measures: $measures, filter: $filter, orders: $orders) {\n    measures\n  }\n}\n\nfragment PromotionReportFormContainer_project on Project {\n  id\n  nendPromotions {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n  ...AdvertiserReportList_project\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4bb1d9cd470e8ded4cc856a9265b2521';
export default node;
