/* tslint:disable */
/* eslint-disable */
/* @relayHash 73813d0d2fff3e040b62ec89cfe8da42 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteUserInput = {
    id: string;
    clientMutationId?: string | null;
};
export type DeleteUserMutationVariables = {
    input: DeleteUserInput;
};
export type DeleteUserMutationResponse = {
    readonly deleteUser: {
        readonly deletedUserId: string;
    } | null;
};
export type DeleteUserMutation = {
    readonly response: DeleteUserMutationResponse;
    readonly variables: DeleteUserMutationVariables;
};



/*
mutation DeleteUserMutation(
  $input: DeleteUserInput!
) {
  deleteUser(input: $input) {
    deletedUserId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedUserId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteUserMutation",
    "id": null,
    "text": "mutation DeleteUserMutation(\n  $input: DeleteUserInput!\n) {\n  deleteUser(input: $input) {\n    deletedUserId\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e601a0185f9615126143dd7186250a14';
export default node;
