import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { RouteComponentProps } from "react-router";

import { NendPromotionDetail_Query } from "../__relay_artifacts__/NendPromotionDetail_Query.graphql";
import NendPromotionDetail from "../components/NendPromotionDetail";
import Environment from "../lib/Environment";

type Props = RouteComponentProps<{ id: string; projectId: string }>;

const query = graphql`
  query NendPromotionDetail_Query($projectId: ID!, $nendPromotionId: ID!) {
    project(id: $projectId) {
      ...NendPromotionDetail_project
    }
  }
`;

const NendPromotionDetailPage: React.FC<Props> = ({ match }) => (
  <QueryRenderer<NendPromotionDetail_Query>
    environment={Environment}
    query={query}
    cacheConfig={{ force: true }}
    variables={{
      projectId: match.params.projectId,
      nendPromotionId: match.params.id
    }}
    render={({ error, props }) => {
      if (error) return <div>{error.message}</div>;
      if (!props) return <div>Loading...</div>;
      return <NendPromotionDetail project={props.project!} />;
    }}
  />
);

export default NendPromotionDetailPage;
