/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignGroupEditButton_nendCampaignGroup = {
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupEditForm_nendCampaignGroup">;
    readonly " $refType": "NendCampaignGroupEditButton_nendCampaignGroup";
};
export type NendCampaignGroupEditButton_nendCampaignGroup$data = NendCampaignGroupEditButton_nendCampaignGroup;
export type NendCampaignGroupEditButton_nendCampaignGroup$key = {
    readonly " $data"?: NendCampaignGroupEditButton_nendCampaignGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupEditButton_nendCampaignGroup">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendCampaignGroupEditButton_nendCampaignGroup",
  "type": "NendCampaignGroup",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NendCampaignGroupEditForm_nendCampaignGroup",
      "args": null
    }
  ]
};
(node as any).hash = '5e132a7dda1c8f4ec995dd340300b2d3';
export default node;
