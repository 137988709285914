import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteNendCampaignMutation } from "../__relay_artifacts__/DeleteNendCampaignMutation.graphql";

const mutation = graphql`
  mutation DeleteNendCampaignMutation($input: DeleteNendCampaignInput!) {
    deleteNendCampaign(input: $input) {
      deletedCampaignId
    }
  }
`;

const commit = (
  environment: Environment,
  campaignGroupId: string,
  campaignId: string
) =>
  commitMutation<DeleteNendCampaignMutation>(environment, {
    mutation,
    variables: { input: { campaignId } },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: campaignGroupId,
        connectionKeys: [{ key: "NendCampaignListTableCard_nendCampaigns" }],
        pathToConnection: ["nendCampaignGroup", "nendCampaigns"],
        deletedIDFieldName: "deletedCampaignId"
      }
    ]
  });

export default { commit };
