/* tslint:disable */
/* eslint-disable */
/* @relayHash 0baeaec71ecf3e64c7786da4ba5cde19 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionListTableCard_QueryVariables = {
    projectId: string;
    first?: number | null;
    after?: string | null;
};
export type NendPromotionListTableCard_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"NendPromotionListTableCard_project">;
    } | null;
};
export type NendPromotionListTableCard_Query = {
    readonly response: NendPromotionListTableCard_QueryResponse;
    readonly variables: NendPromotionListTableCard_QueryVariables;
};



/*
query NendPromotionListTableCard_Query(
  $projectId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    ...NendPromotionListTableCard_project
    id
  }
}

fragment NendPromotionEditButton_nendPromotion on NendPromotion {
  ...NendPromotionEditForm_nendPromotion
}

fragment NendPromotionEditForm_nendPromotion on NendPromotion {
  id
  title
  nendPromotionId
  fee
}

fragment NendPromotionListTableCard_project on Project {
  id
  nendPromotions(first: $first, after: $after) {
    edges {
      node {
        id
        title
        nendPromotionId
        fee
        createdAt
        ...NendPromotionEditButton_nendPromotion
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NendPromotionListTableCard_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NendPromotionListTableCard_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NendPromotionListTableCard_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotions",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "NendPromotionConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NendPromotionEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NendPromotion",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "nendPromotionId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fee",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "nendPromotions",
            "args": (v3/*: any*/),
            "handle": "connection",
            "key": "NendPromotionListTableCard_nendPromotions",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NendPromotionListTableCard_Query",
    "id": null,
    "text": "query NendPromotionListTableCard_Query(\n  $projectId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    ...NendPromotionListTableCard_project\n    id\n  }\n}\n\nfragment NendPromotionEditButton_nendPromotion on NendPromotion {\n  ...NendPromotionEditForm_nendPromotion\n}\n\nfragment NendPromotionEditForm_nendPromotion on NendPromotion {\n  id\n  title\n  nendPromotionId\n  fee\n}\n\nfragment NendPromotionListTableCard_project on Project {\n  id\n  nendPromotions(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        title\n        nendPromotionId\n        fee\n        createdAt\n        ...NendPromotionEditButton_nendPromotion\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b78b1d0943461c164a14f77e854f2432';
export default node;
