/* tslint:disable */
/* eslint-disable */
/* @relayHash 8ede86eaa6dfd0ec615761e57a531986 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteNendPromotionInput = {
    promotionId: string;
    clientMutationId?: string | null;
};
export type DeleteNendPromotionMutationVariables = {
    input: DeleteNendPromotionInput;
};
export type DeleteNendPromotionMutationResponse = {
    readonly deleteNendPromotion: {
        readonly deletedPromotionId: string;
    } | null;
};
export type DeleteNendPromotionMutation = {
    readonly response: DeleteNendPromotionMutationResponse;
    readonly variables: DeleteNendPromotionMutationVariables;
};



/*
mutation DeleteNendPromotionMutation(
  $input: DeleteNendPromotionInput!
) {
  deleteNendPromotion(input: $input) {
    deletedPromotionId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteNendPromotionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteNendPromotion",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteNendPromotionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedPromotionId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteNendPromotionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteNendPromotionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteNendPromotionMutation",
    "id": null,
    "text": "mutation DeleteNendPromotionMutation(\n  $input: DeleteNendPromotionInput!\n) {\n  deleteNendPromotion(input: $input) {\n    deletedPromotionId\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '6d9b7082d9247a6a46cc81d9a6ec9d76';
export default node;
