import { GraphQLTaggedNode } from "react-relay";
import {
  useFragment as _useFragment,
  useQuery as _useQuery,
  useRefetch as _useRefetch,
  useRelayEnvironment as _useRelayEnvironment
} from "relay-hooks";
import { CacheConfig, Environment, OperationType } from "relay-runtime";

type RefetchOptions = {
  force?: boolean;
  fetchPolicy?: "store-or-network" | "network-only";
};

type RefetchFunction = <TOperationType extends any>(
  taggedNode: GraphQLTaggedNode,
  refetchVariables: TOperationType["variables"],
  renderVariables?: any,
  observerOrCallback?: (err: any) => void,
  options?: RefetchOptions
) => {
  dispose(): void;
};

// @ts-ignore
export const useRefetch: <FragmentRef, FragmentResult>(
  fragmentDef: GraphQLTaggedNode,
  fragmentRef: FragmentRef
) => [FragmentResult, RefetchFunction] = _useRefetch;

// @ts-ignore
export const useFragment: <FragmentResult, FragmentRef = any>(
  fragmentDef: GraphQLTaggedNode,
  fragmentRef: FragmentRef
) => FragmentResult = _useFragment;

// @ts-ignore
export const useQuery: <TOperationType extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: TOperationType["variables"],
  options?: {
    fetchPolicy?:
      | "store-only"
      | "store-or-network"
      | "store-and-network"
      | "network-only";
    networkCacheConfig?: CacheConfig;
  }
) => {
  error: Error;
  props: TOperationType["response"];
  retry: (_cacheConfigOverride?: CacheConfig) => void;
  cached?: boolean;
} = _useQuery;

// @ts-ignore
export const useRelayEnvironment: () => Environment = _useRelayEnvironment;
