/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRole = "ADMIN" | "NORMAL" | "%future added value";
export type ProjectDrawerMenuList_viewer = {
    readonly role: UserRole;
    readonly projects: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly viewerCanAdministrate: boolean;
            } | null;
        } | null> | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"AccountButton_viewer">;
    readonly " $refType": "ProjectDrawerMenuList_viewer";
};
export type ProjectDrawerMenuList_viewer$data = ProjectDrawerMenuList_viewer;
export type ProjectDrawerMenuList_viewer$key = {
    readonly " $data"?: ProjectDrawerMenuList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectDrawerMenuList_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectDrawerMenuList_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "projects",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanAdministrate",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AccountButton_viewer",
      "args": null
    }
  ]
};
(node as any).hash = 'f0de54fec0adcb8091da85fe323119db';
export default node;
