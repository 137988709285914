import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";
import { useHistory, useParams } from "react-router";

import { NendPromotionDetail_project } from "../__relay_artifacts__/NendPromotionDetail_project.graphql";
import NendPromotionEditButton from "../containers/NendPromotionEditButton";
import DeleteNendPromotionMutation from "../mutations/DeleteNendPromotionMutation";
import ConfirmButton from "./atoms/ConfirmButton";
import PageHeader from "./PageHeader";

type Props = {
  relay: RelayProp;
  project: NendPromotionDetail_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NendPromotionDetail: React.FC<Props> = ({
  relay,
  project: { nendPromotion }
}) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  if (!nendPromotion) {
    throw new Error("assertion failed");
  }

  const handlePromotionDelete = React.useCallback(async () => {
    await DeleteNendPromotionMutation.commit(
      // @ts-ignore
      relay.environment,
      projectId,
      nendPromotion.id
    );
    history.push(`/projects/${projectId}/promotions`);
  }, [nendPromotion.id, history, projectId, relay.environment]);

  return (
    <>
      <PageHeader
        title={""} // promotion.title
        backTo={`/projects/${projectId}/promotions`}
        tabs={
          <Tabs
            value={selectedTabIndex}
            onChange={(_, v) => setSelectedTabIndex(v)}
          >
            <Tab label="広告" />
          </Tabs>
        }
        breadcrumbs={[
          {
            title: "プロモーション一覧",
            link: `/projects/${projectId}/promotions`
          },
          {
            title: "プロモーション詳細",
            link: `/projects/${projectId}/promotions/${nendPromotion.id}`
          }
        ]}
      >
        <NendPromotionEditButton promotion={nendPromotion}>
          <Icon className={classes.leftIcon}>edit</Icon>
          <span>編集</span>
        </NendPromotionEditButton>
        <ConfirmButton
          key="delete"
          color="inherit"
          onAgree={handlePromotionDelete}
          className={classes.button}
          confirmTitle="このキャンペーンを削除しますか？"
        >
          <Icon className={classes.leftIcon}>delete</Icon>
          <span>削除</span>
        </ConfirmButton>
      </PageHeader>

      <div className={classes.root}>
        {selectedTabIndex === 0 && (
          <>
            <Paper className={classes.paper}>
              <Toolbar>
                <Typography variant="subtitle1">基本設定</Typography>
              </Toolbar>
              <List>
                <ListItem>
                  <ListItemText
                    primary="名前"
                    secondary={nendPromotion.title}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="作成日"
                    secondary={DateFns.format(
                      DateFns.parseISO(nendPromotion.createdAt),
                      "MM/dd/yyyy"
                    )}
                  />
                </ListItem>
              </List>
            </Paper>
          </>
        )}
      </div>
    </>
  );
};

export default createFragmentContainer(NendPromotionDetail, {
  project: graphql`
    fragment NendPromotionDetail_project on Project {
      nendPromotion(id: $nendPromotionId) {
        id
        title
        createdAt
        ...NendPromotionEditButton_nendPromotion
      }
    }
  `
});
