import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateMembershipRoleInput,
  UpdateMembershipRoleMutation
} from "../__relay_artifacts__/UpdateMembershipRoleMutation.graphql";

const mutation = graphql`
  mutation UpdateMembershipRoleMutation($input: UpdateMembershipRoleInput!) {
    updateMembershipRole(input: $input) {
      membership {
        id
        role
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateMembershipRoleInput) =>
  commitMutation<UpdateMembershipRoleMutation>(environment, {
    mutation,
    variables: {
      input
    }
  });

export default { commit };
