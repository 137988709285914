import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";

export interface FormValues {
  email: string;
  // password: string;
}

type Props = FormikProps<FormValues>;

const AccountForm: React.FC<Props> = ({ isSubmitting }) => {
  return (
    <Form>
      <FormikTextField name="email" label="Email" margin="normal" />
      <CardActions className="md-divider-border md-divider-border--top">
        <Button variant="outlined" type="submit" disabled={isSubmitting}>
          保存
        </Button>
      </CardActions>
    </Form>
  );
};

export default AccountForm;
