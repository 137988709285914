import "@firebase/auth";

import firebase from "@firebase/app";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import useAsyncFn from "react-use/lib/useAsyncFn";

import { Top_Query } from "../__relay_artifacts__/Top_Query.graphql";
import LinkButton from "../components/atoms/LinkButton";
import ProjectList from "../components/ProjectList";
import Environment from "../lib/Environment";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  card: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },

  cardAction: {
    width: "100%"
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}));

const query = graphql`
  query Top_Query {
    viewer {
      id
      email
      role
      profilePicture
      ...ProjectList_viewer
    }
  }
`;

const Index: React.FC = () => {
  const classes = useStyles();

  const [state, logout] = useAsyncFn(async () => {
    if (!firebase.auth) throw new Error("firebase.auth is not set");
    return firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.replace(`/signin`);
      });
  });

  return (
    <main className={classes.main}>
      <Card className={classes.card}>
        <CardHeader title="プロジェクトの選択" />
        <QueryRenderer<Top_Query>
          environment={Environment}
          query={query}
          cacheConfig={{ force: true }}
          variables={{}}
          render={({ error, props: res }) => {
            if (error) {
              return <div>{error.message}</div>;
            } else if (res) {
              return (
                <>
                  <CardContent>
                    <ProjectList viewer={res.viewer} />
                  </CardContent>
                  {res.viewer.role === "ADMIN" && (
                    <CardActions className={classes.cardAction}>
                      <LinkButton
                        color="primary"
                        variant="contained"
                        fullWidth
                        to="/projects/create"
                      >
                        新規プロジェクト作成
                      </LinkButton>
                    </CardActions>
                  )}
                </>
              );
            }
            return <div>Loading</div>;
          }}
        />
      </Card>
      <Box mt={4} display="flex" flexDirection="column">
        <Button color="primary" onClick={logout} disabled={state.loading}>
          ログアウトする
        </Button>
      </Box>
    </main>
  );
};

export default Index;
