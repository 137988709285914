import "@firebase/auth";

import "./globalState";

import firebase from "@firebase/app";
import { createMuiTheme } from "@material-ui/core";
import { deepPurple, pink } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { init } from "@sentry/browser";
import accounting from "accounting";
import ja from "date-fns/locale/ja";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RelayEnvironmentProvider } from "relay-hooks";

import { App } from "./App";
import environment from "./lib/Environment";
import { LocalizedDateFnsUtils } from "./lib/LocalizedDateFnsUtils";

init({
  dsn: process.env.REACT_APP_SENTRY_DSN
});

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
});

accounting.settings = {
  ...accounting.settings,
  currency: {
    ...accounting.settings.currency,
    symbol: "¥",
    precision: 0
  }
};

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: pink
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={LocalizedDateFnsUtils} locale={ja}>
        <SnackbarProvider maxSnack={3}>
          <RelayEnvironmentProvider environment={environment}>
            <App />
          </RelayEnvironmentProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
