import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import { MembershipRoleEditForm_membership } from "../__relay_artifacts__/MembershipRoleEditForm_membership.graphql";
import MembershipRoleForm, {
  FormValues,
  schema
} from "../components/MembershipRoleForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import UpdateMembershipRoleMutation from "../mutations/UpdateMembershipRoleMutation";

type Props = {
  relay: RelayProp;
  membership: MembershipRoleEditForm_membership;
  onSubmitCompleted: () => void;
};

const MembershipRoleEditForm: React.FC<Props> = ({
  relay,
  membership,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = React.useMemo(
    () => ({ role: membership.role } as FormValues),
    [membership]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const {
          updateMembershipRole
          // @ts-ignore
        } = await UpdateMembershipRoleMutation.commit(relay.environment, {
          id: membership.id,
          ...values
        });

        const _membership =
          updateMembershipRole && updateMembershipRole.membership;
        if (!_membership) throw new Error("assertion failed");

        enqueueSnackbar("メンバー役割を更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, membership.id, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => <MembershipRoleForm {...formikHelpers} />}
    </Formik>
  );
};

export default createFragmentContainer(MembershipRoleEditForm, {
  membership: graphql`
    fragment MembershipRoleEditForm_membership on Membership {
      id
      role
    }
  `
});
