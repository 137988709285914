import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import { NendCampaignGroupEditForm_nendCampaignGroup } from "../__relay_artifacts__/NendCampaignGroupEditForm_nendCampaignGroup.graphql";
import NendCampaignGroupForm, {
  FormValues,
  schema
} from "../components/NendCampaignGroupForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import UpdateNendCampaignGroupMutation from "../mutations/UpdateNendCampaignGroupMutation";

type Props = {
  relay: RelayProp;
  campaignGroup: NendCampaignGroupEditForm_nendCampaignGroup;
  onSubmitCompleted: () => void;
};

const NendCampaignGroupEditForm: React.FC<Props> = ({
  relay,
  campaignGroup,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = React.useMemo(
    () => ({
      title: campaignGroup.title,
      nendCampaignGroupId: campaignGroup.nendCampaignGroupId
    }),
    [campaignGroup]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const {
          updateNendCampaignGroup
          // @ts-ignore
        } = await UpdateNendCampaignGroupMutation.commit(relay.environment, {
          id: campaignGroup.id,
          title: values.title,
          nendCampaignGroupId: values.nendCampaignGroupId
        });

        const _campaignGroup =
          updateNendCampaignGroup && updateNendCampaignGroup.nendCampaignGroup;
        if (!_campaignGroup) throw new Error("assertion failed");

        enqueueSnackbar("キャンペーングループを更新しました", {
          variant: "success"
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [campaignGroup.id, enqueueSnackbar, onSubmitCompleted, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendCampaignGroupForm
          {...formikHelpers}
          title="キャンペーングループの編集"
        />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendCampaignGroupEditForm, {
  nendCampaignGroup: graphql`
    fragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {
      id
      title
      nendCampaignGroupId
      createdAt
    }
  `
});
