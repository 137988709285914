import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";

import PageHeader from "../../components/PageHeader";
import UserListTableCard from "../../components/UserListTableCard";
import Environment from "../../lib/Environment";

type PageContentInnerProps = {};
interface PageContentOutterProps {
  response: any;
}

const PageContent: React.FC<PageContentInnerProps & PageContentOutterProps> = ({
  response
}) => {
  return (
    <>
      <UserListTableCard root={response} />
    </>
  );
};

const query = graphql`
  query UserList_Query($count: Int, $cursor: String, $orderBy: UserOrder) {
    ...UserListTableCard_root
  }
`;

const UserList: React.FC = props => {
  return (
    <>
      <PageHeader title="ユーザー" />
      <QueryRenderer
        environment={Environment}
        query={query}
        cacheConfig={{ force: true }}
        variables={{
          count: 25,
          orderBy: { field: "CREATED_AT", direction: "DESC" }
        }}
        render={({ error, props: res }) => {
          if (error) {
            return <div>{error.message}</div>;
          }
          if (!res) {
            return <div>Loading...</div>;
          }
          return <PageContent response={res} {...props} />;
        }}
      />
    </>
  );
};

export default UserList;
