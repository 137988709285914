import Icon from "@material-ui/core/Icon";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { RouteComponentProps } from "react-router";

import LinkButton from "../../components/atoms/LinkButton";
import MembershipList from "../../components/MembershipList";
import PageHeader from "../../components/PageHeader";
import Environment from "../../lib/Environment";
import CompactNendPromotionList from "./CompactNendPromotionList";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

type PageContentProps = {
  projectId: string;
  response: any;
};

const PageContent: React.FC<PageContentProps> = ({ projectId, response }) => {
  const classes = useStyles();
  return (
    <>
      <PageHeader title="ホーム">
        <LinkButton
          color="inherit"
          className={classes.button}
          to={`/projects/${projectId}/edit`}
        >
          <Icon className={classes.leftIcon}>edit</Icon>
          <span>プロジェクトを編集する</span>
        </LinkButton>
      </PageHeader>
      <CompactNendPromotionList project={response.project} />
      <MembershipList project={response.project} viewer={response.viewer} />
    </>
  );
};

type Props = RouteComponentProps<{ projectId: string }>;

const query = graphql`
  query Home_Query($projectId: ID!, $first: Int, $after: String) {
    viewer {
      ...MembershipList_viewer
    }
    project(id: $projectId) {
      ...MembershipList_project
      ...CompactNendPromotionList_project
    }
  }
`;

const Home: React.FC<Props> = ({
  match: {
    params: { projectId }
  }
}) => (
  <QueryRenderer
    environment={Environment}
    query={query}
    cacheConfig={{ force: true }}
    variables={{
      projectId: projectId,
      first: 25
    }}
    render={({ error, props: response }) => {
      if (error) {
        return <div>{error.message}</div>;
      }
      if (!response) {
        return <div>Loading...</div>;
      }
      return <PageContent response={response} projectId={projectId} />;
    }}
  />
);

export default Home;
