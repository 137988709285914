import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteNendCampaignGroupMutation } from "../__relay_artifacts__/DeleteNendCampaignGroupMutation.graphql";

const mutation = graphql`
  mutation DeleteNendCampaignGroupMutation(
    $input: DeleteNendCampaignGroupInput!
  ) {
    deleteNendCampaignGroup(input: $input) {
      deletedCampaignGroupId
    }
  }
`;

const commit = (
  environment: Environment,
  promotionId: string,
  campaignGroupId: string
) =>
  commitMutation<DeleteNendCampaignGroupMutation>(environment, {
    mutation,
    variables: { input: { campaignGroupId } },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: promotionId,
        connectionKeys: [
          { key: "NendCampaignGroupListTableCard_nendCampaignGroups" }
        ],
        pathToConnection: ["nendPromotion", "nendCampaignGroups"],
        deletedIDFieldName: "deletedCampaignGroupId"
      }
    ]
  });

export default { commit };
