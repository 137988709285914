import Button, { ButtonProps } from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  }
}));

type Props = {
  confirmTitle: string;
  confirmContent?: string;
  onAgree: (changeDialog: (state: boolean) => void) => void;
} & ButtonProps;

const ConfirmButton: React.FC<Props> = ({
  variant,
  color,
  children,
  confirmTitle,
  confirmContent,
  onAgree
}) => {
  const classes = useStyles();
  const [dialogOpen, openDialog, closeDialog, changeDialog] = useOpenState();

  const handleDialogButtonClick = React.useCallback<React.MouseEventHandler>(
    event => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const handleDialogClose = React.useCallback<React.MouseEventHandler>(
    event => {
      event.stopPropagation();
      closeDialog();
    },
    [closeDialog]
  );

  const handleCancelButtonClick = React.useCallback<React.MouseEventHandler>(
    event => {
      event.stopPropagation();
      closeDialog();
    },
    [closeDialog]
  );

  const handleAgreeButtonClick = React.useCallback<React.MouseEventHandler>(
    event => {
      event.stopPropagation();
      onAgree((state: boolean) => changeDialog(state));
    },
    [changeDialog, onAgree]
  );

  return (
    <>
      <Button
        className={classes.button}
        variant={variant}
        color={color}
        onClick={handleDialogButtonClick}
      >
        {children}
      </Button>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelButtonClick}>
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={handleAgreeButtonClick}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmButton;
