import { setGlobal } from "reactn";

import { AdvertiserReportFormValues } from "./pages/AdvertiserReport";

type AdvertiserReportCache = {
  [promotionId: string]: AdvertiserReportFormValues | null;
};

declare module "reactn/default" {
  export interface State {
    advertiserReportCache: AdvertiserReportCache;
  }
}

// NOTE: set initial state
setGlobal({
  publisherReportCache: {},
  advertiserReportCache: {}
});
