import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import { NendPromotionEditForm_nendPromotion } from "../__relay_artifacts__/NendPromotionEditForm_nendPromotion.graphql";
import NendPromotionForm, {
  FormValues,
  schema
} from "../components/NendPromotionForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import UpdateNendPromotionMutation from "../mutations/UpdateNendPromotionMutation";

type Props = {
  relay: RelayProp;
  promotion: NendPromotionEditForm_nendPromotion;
  onSubmitCompleted: () => void;
};

const NendPromotionEditForm: React.FC<Props> = ({
  relay,
  promotion,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = React.useMemo(
    () => ({
      title: promotion.title,
      nendPromotionId: promotion.nendPromotionId,
      fee: promotion.fee
    }),
    [promotion]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const {
          updateNendPromotion
          // @ts-ignore
        } = await UpdateNendPromotionMutation.commit(relay.environment, {
          id: promotion.id,
          ...values
        });

        const _promotion =
          updateNendPromotion && updateNendPromotion.nendPromotion;
        if (!_promotion) throw new Error("assertion failed");

        enqueueSnackbar("プロモーションを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, promotion.id, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendPromotionForm {...formikHelpers} title="プロモーションの編集" />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendPromotionEditForm, {
  nendPromotion: graphql`
    fragment NendPromotionEditForm_nendPromotion on NendPromotion {
      id
      title
      nendPromotionId
      fee
    }
  `
});
