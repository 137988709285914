import * as React from "react";
import { graphql } from "react-relay";
import { Redirect } from "react-router";
import { useParams } from "react-router";

import { ProjectRoot_Query } from "../__relay_artifacts__/ProjectRoot_Query.graphql";
import { useQuery } from "../lib/relay-hooks";

const query = graphql`
  query ProjectRoot_Query($projectId: ID!) {
    project(id: $projectId) {
      id
    }
  }
`;

const useProject = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { props } = useQuery<ProjectRoot_Query>(query, { projectId });
  return {
    loading: !props,
    project: props && props.project
  };
};

export const ProjectRoot: React.FC = ({ children }) => {
  const { loading, project } = useProject();
  if (loading) return <>Loading...</>;
  if (!project) return <Redirect to="/" />;
  return <>{children}</>;
};
