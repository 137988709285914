import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendCampaignList_project } from "../__relay_artifacts__/NendCampaignList_project.graphql";
import NendCampaignListTableCard from "./NendCampaignListTableCard";
import PageHeader from "./PageHeader";

type Props = {
  project: NendCampaignList_project;
};

const NendCampaignList: React.FC<Props> = ({ project }) => {
  const [selectedTabIndex, selectTabIndex] = useState(0);
  return (
    <>
      <PageHeader
        title="キャンペーン"
        tabs={
          <Tabs value={selectedTabIndex} onChange={(_, v) => selectTabIndex(v)}>
            <Tab label="キャンペーン一覧" />
          </Tabs>
        }
      />
      {selectedTabIndex === 0 && (
        <NendCampaignListTableCard project={project} />
      )}
    </>
  );
};

export default createFragmentContainer(NendCampaignList, {
  project: graphql`
    fragment NendCampaignList_project on Project {
      ...NendCampaignListTableCard_project
    }
  `
});
