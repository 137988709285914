/* tslint:disable */
/* eslint-disable */
/* @relayHash fd3d62b49e189be0417e8f7ffec78cf8 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionDetail_QueryVariables = {
    projectId: string;
    nendPromotionId: string;
};
export type NendPromotionDetail_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"NendPromotionDetail_project">;
    } | null;
};
export type NendPromotionDetail_Query = {
    readonly response: NendPromotionDetail_QueryResponse;
    readonly variables: NendPromotionDetail_QueryVariables;
};



/*
query NendPromotionDetail_Query(
  $projectId: ID!
  $nendPromotionId: ID!
) {
  project(id: $projectId) {
    ...NendPromotionDetail_project
    id
  }
}

fragment NendPromotionDetail_project on Project {
  nendPromotion(id: $nendPromotionId) {
    id
    title
    createdAt
    ...NendPromotionEditButton_nendPromotion
  }
}

fragment NendPromotionEditButton_nendPromotion on NendPromotion {
  ...NendPromotionEditForm_nendPromotion
}

fragment NendPromotionEditForm_nendPromotion on NendPromotion {
  id
  title
  nendPromotionId
  fee
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nendPromotionId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NendPromotionDetail_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NendPromotionDetail_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NendPromotionDetail_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotion",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "nendPromotionId"
              }
            ],
            "concreteType": "NendPromotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nendPromotionId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fee",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NendPromotionDetail_Query",
    "id": null,
    "text": "query NendPromotionDetail_Query(\n  $projectId: ID!\n  $nendPromotionId: ID!\n) {\n  project(id: $projectId) {\n    ...NendPromotionDetail_project\n    id\n  }\n}\n\nfragment NendPromotionDetail_project on Project {\n  nendPromotion(id: $nendPromotionId) {\n    id\n    title\n    createdAt\n    ...NendPromotionEditButton_nendPromotion\n  }\n}\n\nfragment NendPromotionEditButton_nendPromotion on NendPromotion {\n  ...NendPromotionEditForm_nendPromotion\n}\n\nfragment NendPromotionEditForm_nendPromotion on NendPromotion {\n  id\n  title\n  nendPromotionId\n  fee\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0618e7d8a5fbfddc0b3af8c5a4201fbe';
export default node;
