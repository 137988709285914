/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectDrawerMenuList_project = {
    readonly id: string;
    readonly name: string;
    readonly viewerCanReadCampaign: boolean;
    readonly viewerCanAdministrate: boolean;
    readonly " $refType": "ProjectDrawerMenuList_project";
};
export type ProjectDrawerMenuList_project$data = ProjectDrawerMenuList_project;
export type ProjectDrawerMenuList_project$key = {
    readonly " $data"?: ProjectDrawerMenuList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectDrawerMenuList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectDrawerMenuList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanReadCampaign",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanAdministrate",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'ce26eabc6d30ed3eaacc04e31b915219';
export default node;
