import { useSnackbar } from "notistack";
import * as React from "react";

import ConfirmButton from "../components/atoms/ConfirmButton";
import { useRelayEnvironment } from "../lib/relay-hooks";
import DeleteNendCampaignMutation from "../mutations/DeleteNendCampaignMutation";

type Props = {
  campaignGroupId: string;
  campaignId: string;
};

export const NendCampaignDeleteButton: React.FC<Props> = ({
  campaignGroupId,
  campaignId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();

  const handleDelete = React.useCallback(async () => {
    try {
      const { deleteNendCampaign } = await DeleteNendCampaignMutation.commit(
        environment,
        campaignGroupId,
        campaignId
      );

      const id = deleteNendCampaign && deleteNendCampaign.deletedCampaignId;
      if (!id) throw new Error("assertion failed");

      enqueueSnackbar(`キャンペーンを削除しました。`, {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [campaignGroupId, campaignId, enqueueSnackbar, environment]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このキャンペーンを削除しますか？"
      confirmContent=""
      onAgree={async changeModal => {
        await handleDelete();
        changeModal(false);
      }}
    >
      削除
    </ConfirmButton>
  );
};
