import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";
import { useHistory } from "react-router";

import { ProjectEditForm_project } from "../__relay_artifacts__/ProjectEditForm_project.graphql";
import ProjectForm, { FormValues, schema } from "../components/ProjectForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import UpdateProjectMutation from "../mutations/UpdateProjectMutation";

type Props = {
  relay: RelayProp;
  project: ProjectEditForm_project;
};

const ProjectEditForm: React.FC<Props> = ({ relay, project: { id, name } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const initialValues = React.useMemo(() => ({ name }), [name]);

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { updateProject } = await UpdateProjectMutation.commit(
          // @ts-ignore
          relay.environment,
          { id, name: values.name }
        );

        const _project = updateProject && updateProject.project;
        if (!_project) throw new Error("assertion failed");

        enqueueSnackbar("プロジェクトを更新しました", { variant: "success" });
        history.push(`/projects/${id}/home`);
      } catch (err) {
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, history, id, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => <ProjectForm {...formikHelpers} />}
    </Formik>
  );
};

export default createFragmentContainer(ProjectEditForm, {
  project: graphql`
    fragment ProjectEditForm_project on Project {
      name
      id
    }
  `
});
