/* tslint:disable */
/* eslint-disable */
/* @relayHash e23b7a30f37df0790b4c3eea815e3a51 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteNendCampaignGroupInput = {
    campaignGroupId: string;
    clientMutationId?: string | null;
};
export type DeleteNendCampaignGroupMutationVariables = {
    input: DeleteNendCampaignGroupInput;
};
export type DeleteNendCampaignGroupMutationResponse = {
    readonly deleteNendCampaignGroup: {
        readonly deletedCampaignGroupId: string;
    } | null;
};
export type DeleteNendCampaignGroupMutation = {
    readonly response: DeleteNendCampaignGroupMutationResponse;
    readonly variables: DeleteNendCampaignGroupMutationVariables;
};



/*
mutation DeleteNendCampaignGroupMutation(
  $input: DeleteNendCampaignGroupInput!
) {
  deleteNendCampaignGroup(input: $input) {
    deletedCampaignGroupId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteNendCampaignGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteNendCampaignGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteNendCampaignGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedCampaignGroupId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteNendCampaignGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteNendCampaignGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteNendCampaignGroupMutation",
    "id": null,
    "text": "mutation DeleteNendCampaignGroupMutation(\n  $input: DeleteNendCampaignGroupInput!\n) {\n  deleteNendCampaignGroup(input: $input) {\n    deletedCampaignGroupId\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0c1f316bc2bb7558cd924114cad8fa66';
export default node;
