import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql
} from "react-relay";

import { CompactNendPromotionListTableCard_project } from "../../__relay_artifacts__/CompactNendPromotionListTableCard_project.graphql";
import TableCell from "../../components/atoms/TableCell";

type Props = {
  relay: RelayPaginationProp;
  project: CompactNendPromotionListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  table: {
    minWidth: 700
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));

const useStateHandlers = () => {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);

  const handleChangePage = React.useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const handleChangePerPage = React.useCallback((perPageNumber: number) => {
    setPerPage(perPageNumber);
  }, []);

  return {
    page,
    perPage,
    handleChangePage,
    handleChangePerPage
  };
};

const CompactNendPromotionListTableCard: React.FC<Props> = ({
  relay,
  project: { nendPromotions }
}) => {
  const classes = useStyles();
  const {
    page,
    perPage,
    handleChangePage,
    handleChangePerPage
  } = useStateHandlers();
  const totalCount = nendPromotions.totalCount;
  const edges = nendPromotions && nendPromotions.edges;
  if (!edges) throw new Error("assertion failed");

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          プロモーション一覧
        </Typography>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>タイトル</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.slice(page * perPage, page * perPage + perPage).map(edge => {
              const node = edge && edge.node;
              if (!node) throw new Error("assertion failed");
              return (
                <TableRow key={node.id} hover>
                  <TableCell>{node.title || "設定無し"}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={(_, pageNumber) => {
          if (relay.hasMore()) relay.loadMore(perPage);
          handleChangePage(pageNumber);
        }}
        onChangeRowsPerPage={event => {
          handleChangePerPage(parseInt(event.target.value));
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CompactNendPromotionListTableCard,
  {
    project: graphql`
      fragment CompactNendPromotionListTableCard_project on Project {
        id
        nendPromotions(first: $first, after: $after)
          @connection(
            key: "NendPromotionListTableCard_nendPromotions"
            filters: []
          ) {
          edges {
            node {
              id
              title
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `
  },
  {
    getConnectionFromProps: props => props.project.nendPromotions as any,
    getVariables: (props, { count, cursor }) => ({
      projectId: props.project.id,
      first: count,
      after: cursor
    }),
    query: graphql`
      query CompactNendPromotionListTableCard_Query(
        $projectId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...CompactNendPromotionListTableCard_project
        }
      }
    `
  }
);
