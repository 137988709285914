import MaterialTableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

const useStyles = makeStyles({
  tableCell: {
    whiteSpace: "nowrap"
  }
});

type Props = TableCellProps;

const TableCell: React.FC<Props> = props => {
  const classes = useStyles();
  return <MaterialTableCell className={classes.tableCell} {...props} />;
};

export default TableCell;
