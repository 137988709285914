import { ButtonProps } from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import * as React from "react";

import ConfirmButton from "../components/atoms/ConfirmButton";
import Environment from "../lib/Environment";
import DeleteMembershipMutation from "../mutations/DeleteMembershipMutation";

type Props = {
  membershipId: string;
  projectId: string;
} & ButtonProps;

const MembershipCreateButton: React.FC<Props> = ({
  children,
  membershipId,
  projectId
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(async () => {
    try {
      const { deleteMembership } = await DeleteMembershipMutation.commit(
        Environment,
        membershipId,
        {
          type: "RANGE_DELETE",
          parentID: projectId,
          connectionKeys: [
            {
              key: "MembershipList_memberships"
            }
          ],
          pathToConnection: ["project", "memberships"],
          deletedIDFieldName: "deletedMembershipId"
        }
      );

      const id = deleteMembership && deleteMembership.deletedMembershipId;
      if (!id) throw new Error("assertion failed");

      enqueueSnackbar(`メンバーを削除しました。`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, membershipId, projectId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="inherit"
      confirmTitle="メンバーを削除しますか？"
      confirmContent=""
      onAgree={changeModal => {
        onDelete().then(() => changeModal(false));
      }}
    >
      {children}
    </ConfirmButton>
  );
};

export default MembershipCreateButton;
