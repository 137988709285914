export const measures = [
  { value: "ID" },
  { value: "NAME" },
  { value: "DATE" },
  { value: "OS" },
  { value: "IMPRESSION" },
  { value: "CLICK" },
  { value: "CTR" },
  { value: "CPC" },
  { value: "CVR" },
  { value: "GROSS_BUDGET_CONSUMPTION" },
  { value: "INSTALL" },
  { value: "CPI" }
];

export const orders = [{ value: "DATE" }, { value: "OS" }];
