/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignGroupEditForm_nendCampaignGroup = {
    readonly id: string;
    readonly title: string;
    readonly nendCampaignGroupId: string | null;
    readonly createdAt: string;
    readonly " $refType": "NendCampaignGroupEditForm_nendCampaignGroup";
};
export type NendCampaignGroupEditForm_nendCampaignGroup$data = NendCampaignGroupEditForm_nendCampaignGroup;
export type NendCampaignGroupEditForm_nendCampaignGroup$key = {
    readonly " $data"?: NendCampaignGroupEditForm_nendCampaignGroup$data;
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupEditForm_nendCampaignGroup">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendCampaignGroupEditForm_nendCampaignGroup",
  "type": "NendCampaignGroup",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nendCampaignGroupId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'bd4c06523ded8493576deef535b4fcc1';
export default node;
