import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql
} from "react-relay";

import { UserListTableCard_root } from "../__relay_artifacts__/UserListTableCard_root.graphql";
import UserCreateButton from "../containers/UserCreateButton";
import UserDeleteButton from "../containers/UserDeleteButton";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 1200
  },
  root: {
    margin: theme.spacing(3)
  },
  wrapper: {
    overflowX: "auto"
  }
}));

interface OutterProps {
  root: UserListTableCard_root;
}
type InnerProps = { relay: RelayPaginationProp };
type Props = InnerProps & OutterProps;

const UserListTableCard: React.FC<Props> = props => {
  const { root, relay } = props;
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    relay.loadMore(perPage, () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const edges = root.users && root.users.edges;
  const totalCount = root.users && root.users.totalCount;
  if (!edges) {
    throw new Error("assertion failed");
  }

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          ユーザー一覧
        </Typography>
        <UserCreateButton>
          <Icon>add</Icon>
          <span>作成する</span>
        </UserCreateButton>
      </Toolbar>
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>役割</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.slice(page * perPage, page * perPage + perPage).map(edge => {
              const node = edge && edge.node;
              if (!node) {
                throw new Error("assertion failed");
              }

              const isEditable =
                root.viewer.role === "ADMIN" && root.viewer.id !== node.id;

              return (
                <TableRow key={node.id}>
                  <TableCell>{node.email}</TableCell>
                  <TableCell>{node.role}</TableCell>
                  <TableCell>
                    {isEditable && (
                      <UserDeleteButton userId={node.id}>削除</UserDeleteButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={perPage}
          page={page}
          onChangePage={(_, page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={event => {
            setPerPage(Number(event.target.value));
          }}
        />
      </div>
    </Paper>
  );
};

export default createPaginationContainer(
  UserListTableCard,
  {
    root: graphql`
      fragment UserListTableCard_root on Query {
        viewer {
          id
          role
          email
        }
        users(first: $count, after: $cursor, orderBy: $orderBy)
          @connection(key: "UserListTableCard_users", filters: []) {
          edges {
            node {
              id
              email
              role
            }
          }
          totalCount
        }
      }
    `
  },
  {
    getConnectionFromProps(props) {
      return props.root && (props.root.users as any);
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy
      };
    },
    query: graphql`
      query UserListTableCard_Query(
        $count: Int!
        $cursor: String
        $orderBy: UserOrder
      ) {
        ...UserListTableCard_root
      }
    `
  }
);
