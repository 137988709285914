/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignEditButton_nendCampaign = {
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignEditForm_nendCampaign">;
    readonly " $refType": "NendCampaignEditButton_nendCampaign";
};
export type NendCampaignEditButton_nendCampaign$data = NendCampaignEditButton_nendCampaign;
export type NendCampaignEditButton_nendCampaign$key = {
    readonly " $data"?: NendCampaignEditButton_nendCampaign$data;
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignEditButton_nendCampaign">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendCampaignEditButton_nendCampaign",
  "type": "NendCampaign",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NendCampaignEditForm_nendCampaign",
      "args": null
    }
  ]
};
(node as any).hash = '2b8e11485eba012dc84972073d463d48';
export default node;
