import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import CampaignIcon from "@material-ui/icons/VolumeUp";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { useLocation } from "react-router";

import { ProjectDrawerMenuList_project } from "../__relay_artifacts__/ProjectDrawerMenuList_project.graphql";
import { ProjectDrawerMenuList_viewer } from "../__relay_artifacts__/ProjectDrawerMenuList_viewer.graphql";
import AccountButton from "./AccountButton";
import LinkListItem from "./atoms/LinkListItem";
import LogoutButton from "./LogoutButton";

const matches = (path: string, re: RegExp) => !!path.match(re);

type Props = {
  project: ProjectDrawerMenuList_project;
  viewer: ProjectDrawerMenuList_viewer;
};

const ProjectDrawerMenuList: React.FC<Props> = ({ project, viewer }) => {
  const location = useLocation();
  return (
    <div>
      <List>
        <LinkListItem
          key="home"
          to={`/projects/${project.id}/home`}
          button
          selected={matches(location.pathname, /home/)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="ホーム" />
        </LinkListItem>

        {project.viewerCanAdministrate && (
          <>
            <LinkListItem
              key="campaign"
              button
              to={`/projects/${project.id}/promotions`}
              selected={matches(location.pathname, /promotions/)}
            >
              <ListItemIcon>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary="プロモーション" />
            </LinkListItem>
          </>
        )}

        {project.viewerCanReadCampaign && (
          <LinkListItem
            key="advertiser_report"
            to={`/projects/${project.id}/advertiser_report`}
            button
            selected={matches(location.pathname, /advertiser_report/)}
          >
            <ListItemIcon>
              <InsertChartOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="広告主レポート" />
          </LinkListItem>
        )}
      </List>

      <Divider />

      <List>
        {viewer.role === "ADMIN" && (
          <LinkListItem
            key="admin"
            to={`/admin`}
            button
            selected={matches(location.pathname, /admin/)}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="サービス管理" />
          </LinkListItem>
        )}

        <AccountButton viewer={viewer} />

        <LogoutButton key="logout" />
      </List>
    </div>
  );
};

export default createFragmentContainer(ProjectDrawerMenuList, {
  project: graphql`
    fragment ProjectDrawerMenuList_project on Project {
      id
      name
      viewerCanReadCampaign
      viewerCanAdministrate
    }
  `,
  viewer: graphql`
    fragment ProjectDrawerMenuList_viewer on User {
      ...AccountButton_viewer
      role
      projects {
        edges {
          node {
            id
            name
            viewerCanAdministrate
          }
        }
      }
    }
  `
});
