import Button, { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = ButtonProps & LinkProps;

const ReferensableLink = React.forwardRef<Link, LinkProps>((props, ref) => (
  <Link {...props} ref={ref} />
));

const LinkButton = (props: Props) => (
  // @ts-ignore
  <Button component={ReferensableLink} {...props} />
);

export default LinkButton;
