import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";

import ModalButton from "../components/ModalButton";
import UserCreateForm from "./UserCreateForm";

type Props = ButtonProps;

const UserCreateButton: React.FC<Props> = ({ children }) => (
  <ModalButton
    render={({ close }) => (
      <UserCreateForm title="ユーザー作成" onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);

export default UserCreateButton;
