import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import logo from "../logo_primary.svg";
import AdminDrawerMenuList from "./AdminDrawerMenuList";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  logo: {
    width: 124
  },
  logoWrapper: {
    width: drawerWidth
  }
}));

interface OutterProps {}
type InnerProps = {};
type Props = OutterProps & InnerProps;

const AdminHeader: React.FC = (props: Props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => {
                setMobileOpen(!mobileOpen);
              }}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.logoWrapper}>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          サービス管理
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={() => {
              setMobileOpen(!mobileOpen);
            }}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.toolbar} />
            <AdminDrawerMenuList />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbar} />
            <AdminDrawerMenuList />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default AdminHeader;
