/* tslint:disable */
/* eslint-disable */
/* @relayHash 36beee0837ef372e744e6ebaf49858c4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateNendCampaignGroupInput = {
    id: string;
    title: string;
    nendCampaignGroupId?: string | null;
    clientMutationId?: string | null;
};
export type UpdateNendCampaignGroupMutationVariables = {
    input: UpdateNendCampaignGroupInput;
};
export type UpdateNendCampaignGroupMutationResponse = {
    readonly updateNendCampaignGroup: {
        readonly nendCampaignGroup: {
            readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupEditButton_nendCampaignGroup">;
        } | null;
    } | null;
};
export type UpdateNendCampaignGroupMutation = {
    readonly response: UpdateNendCampaignGroupMutationResponse;
    readonly variables: UpdateNendCampaignGroupMutationVariables;
};



/*
mutation UpdateNendCampaignGroupMutation(
  $input: UpdateNendCampaignGroupInput!
) {
  updateNendCampaignGroup(input: $input) {
    nendCampaignGroup {
      ...NendCampaignGroupEditButton_nendCampaignGroup
      id
    }
  }
}

fragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {
  ...NendCampaignGroupEditForm_nendCampaignGroup
}

fragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {
  id
  title
  nendCampaignGroupId
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNendCampaignGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateNendCampaignGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendCampaignGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendCampaignGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignGroup",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "NendCampaignGroupEditButton_nendCampaignGroup",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateNendCampaignGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendCampaignGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendCampaignGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignGroup",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nendCampaignGroupId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateNendCampaignGroupMutation",
    "id": null,
    "text": "mutation UpdateNendCampaignGroupMutation(\n  $input: UpdateNendCampaignGroupInput!\n) {\n  updateNendCampaignGroup(input: $input) {\n    nendCampaignGroup {\n      ...NendCampaignGroupEditButton_nendCampaignGroup\n      id\n    }\n  }\n}\n\nfragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {\n  ...NendCampaignGroupEditForm_nendCampaignGroup\n}\n\nfragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {\n  id\n  title\n  nendCampaignGroupId\n  createdAt\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '7fb11c5385ee7791d41c2ae7ec771437';
export default node;
