/* tslint:disable */
/* eslint-disable */
/* @relayHash e9c908dbcad5fef33cfda4fb3cbb674b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignGroupList_QueryVariables = {
    projectId: string;
    nendPromotionId: string;
    first?: number | null;
    after?: string | null;
};
export type NendCampaignGroupList_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupList_project">;
    } | null;
};
export type NendCampaignGroupList_Query = {
    readonly response: NendCampaignGroupList_QueryResponse;
    readonly variables: NendCampaignGroupList_QueryVariables;
};



/*
query NendCampaignGroupList_Query(
  $projectId: ID!
  $nendPromotionId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    ...NendCampaignGroupList_project
    id
  }
}

fragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {
  ...NendCampaignGroupEditForm_nendCampaignGroup
}

fragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {
  id
  title
  nendCampaignGroupId
  createdAt
}

fragment NendCampaignGroupListTableCard_project on Project {
  id
  nendPromotion(id: $nendPromotionId) {
    id
    nendCampaignGroups(first: $first, after: $after) {
      edges {
        node {
          id
          title
          createdAt
          nendCampaignGroupId
          ...NendCampaignGroupEditButton_nendCampaignGroup
          __typename
        }
        cursor
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}

fragment NendCampaignGroupList_project on Project {
  ...NendCampaignGroupListTableCard_project
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nendPromotionId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NendCampaignGroupList_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NendCampaignGroupList_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NendCampaignGroupList_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotion",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "nendPromotionId"
              }
            ],
            "concreteType": "NendPromotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nendCampaignGroups",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "NendCampaignGroupConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NendCampaignGroupEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NendCampaignGroup",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "nendCampaignGroupId",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasPreviousPage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "nendCampaignGroups",
                "args": (v3/*: any*/),
                "handle": "connection",
                "key": "NendCampaignGroupListTableCard_nendCampaignGroups",
                "filters": []
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NendCampaignGroupList_Query",
    "id": null,
    "text": "query NendCampaignGroupList_Query(\n  $projectId: ID!\n  $nendPromotionId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    ...NendCampaignGroupList_project\n    id\n  }\n}\n\nfragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {\n  ...NendCampaignGroupEditForm_nendCampaignGroup\n}\n\nfragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {\n  id\n  title\n  nendCampaignGroupId\n  createdAt\n}\n\nfragment NendCampaignGroupListTableCard_project on Project {\n  id\n  nendPromotion(id: $nendPromotionId) {\n    id\n    nendCampaignGroups(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          title\n          createdAt\n          nendCampaignGroupId\n          ...NendCampaignGroupEditButton_nendCampaignGroup\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        hasPreviousPage\n        hasNextPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n}\n\nfragment NendCampaignGroupList_project on Project {\n  ...NendCampaignGroupListTableCard_project\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '8468a30f8ee9bad1b2d243e1cf6282cd';
export default node;
