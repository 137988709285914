import * as React from "react";

import ModalButton from "../components/ModalButton";
import NendCampaignGroupCreateForm from "./NendCampaignGroupCreateForm";

const NendCampaignGroupCreateButton: React.FC = ({ children }) => (
  <ModalButton
    render={({ close }) => (
      <NendCampaignGroupCreateForm onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);

export default NendCampaignGroupCreateButton;
