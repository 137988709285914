/* tslint:disable */
/* eslint-disable */
/* @relayHash debf664158697cb810db084e5cbd7c36 */

import { ConcreteRequest } from "relay-runtime";
export type MembershipRole = "ADMIN" | "ADVERTISER" | "GUEST" | "PUBLISHER" | "%future added value";
export type CreateMembershipInput = {
    projectId: string;
    role: MembershipRole;
    email: string;
    clientMutationId?: string | null;
};
export type CreateMembershipMutationVariables = {
    input: CreateMembershipInput;
};
export type CreateMembershipMutationResponse = {
    readonly createMembership: {
        readonly membershipEdge: {
            readonly node: {
                readonly id: string;
                readonly role: MembershipRole;
                readonly createdAt: string;
                readonly user: {
                    readonly email: string;
                };
            } | null;
        } | null;
    } | null;
};
export type CreateMembershipMutation = {
    readonly response: CreateMembershipMutationResponse;
    readonly variables: CreateMembershipMutationVariables;
};



/*
mutation CreateMembershipMutation(
  $input: CreateMembershipInput!
) {
  createMembership(input: $input) {
    membershipEdge {
      node {
        id
        role
        createdAt
        user {
          email
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateMembershipInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateMembershipMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createMembership",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMembershipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "membershipEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "MembershipEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Membership",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateMembershipMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createMembership",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMembershipPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "membershipEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "MembershipEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Membership",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateMembershipMutation",
    "id": null,
    "text": "mutation CreateMembershipMutation(\n  $input: CreateMembershipInput!\n) {\n  createMembership(input: $input) {\n    membershipEdge {\n      node {\n        id\n        role\n        createdAt\n        user {\n          email\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0bff64b5a9465d9b08cb6a0fb0f1f5eb';
export default node;
