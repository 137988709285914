import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import { NendCampaignEditForm_nendCampaign } from "../__relay_artifacts__/NendCampaignEditForm_nendCampaign.graphql";
import NendCampaignForm, {
  FormValues,
  schema
} from "../components/NendCampaignForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import UpdateNendCampaignMutation from "../mutations/UpdateNendCampaignMutation";

type Props = {
  relay: RelayProp;
  campaign: NendCampaignEditForm_nendCampaign;
  onSubmitCompleted: () => void;
};

const NendCampaignEditForm: React.FC<Props> = ({
  relay,
  campaign,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = React.useMemo(
    () => ({
      title: campaign.title,
      nendCampaignId: campaign.nendCampaignId
    }),
    [campaign]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ title, nendCampaignId }, { setErrors }) => {
      try {
        if (!title) return;
        const { updateNendCampaign } = await UpdateNendCampaignMutation.commit(
          // @ts-ignore
          relay.environment,
          { id: campaign.id, title, nendCampaignId }
        );

        const _campaign = updateNendCampaign && updateNendCampaign.nendCampaign;
        if (!_campaign) throw new Error("assertion failed");

        enqueueSnackbar("キャンペーンを更新しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [campaign.id, enqueueSnackbar, onSubmitCompleted, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendCampaignForm {...formikHelpers} title="キャンペーンの編集" />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendCampaignEditForm, {
  nendCampaign: graphql`
    fragment NendCampaignEditForm_nendCampaign on NendCampaign {
      id
      title
      nendCampaignId
      createdAt
    }
  `
});
