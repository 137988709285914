/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignList_project = {
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignListTableCard_project">;
    readonly " $refType": "NendCampaignList_project";
};
export type NendCampaignList_project$data = NendCampaignList_project;
export type NendCampaignList_project$key = {
    readonly " $data"?: NendCampaignList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendCampaignList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NendCampaignListTableCard_project",
      "args": null
    }
  ]
};
(node as any).hash = '0fc1ca567dd2623ea2ff45b3e09a74da';
export default node;
