/* tslint:disable */
/* eslint-disable */
/* @relayHash f00ed8170d2106f269c263e14959dd20 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateNendCampaignInput = {
    nendCampaignGroupId: string;
    title: string;
    nendCampaignId?: string | null;
    clientMutationId?: string | null;
};
export type CreateNendCampaignMutationVariables = {
    input: CreateNendCampaignInput;
};
export type CreateNendCampaignMutationResponse = {
    readonly createNendCampaign: {
        readonly nendCampaignEdge: {
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"NendCampaignEditButton_nendCampaign">;
            } | null;
        } | null;
    } | null;
};
export type CreateNendCampaignMutation = {
    readonly response: CreateNendCampaignMutationResponse;
    readonly variables: CreateNendCampaignMutationVariables;
};



/*
mutation CreateNendCampaignMutation(
  $input: CreateNendCampaignInput!
) {
  createNendCampaign(input: $input) {
    nendCampaignEdge {
      node {
        ...NendCampaignEditButton_nendCampaign
        id
      }
    }
  }
}

fragment NendCampaignEditButton_nendCampaign on NendCampaign {
  ...NendCampaignEditForm_nendCampaign
}

fragment NendCampaignEditForm_nendCampaign on NendCampaign {
  id
  title
  nendCampaignId
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateNendCampaignInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateNendCampaignMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendCampaignPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendCampaign",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "NendCampaignEditButton_nendCampaign",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateNendCampaignMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendCampaignPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendCampaign",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "nendCampaignId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateNendCampaignMutation",
    "id": null,
    "text": "mutation CreateNendCampaignMutation(\n  $input: CreateNendCampaignInput!\n) {\n  createNendCampaign(input: $input) {\n    nendCampaignEdge {\n      node {\n        ...NendCampaignEditButton_nendCampaign\n        id\n      }\n    }\n  }\n}\n\nfragment NendCampaignEditButton_nendCampaign on NendCampaign {\n  ...NendCampaignEditForm_nendCampaign\n}\n\nfragment NendCampaignEditForm_nendCampaign on NendCampaign {\n  id\n  title\n  nendCampaignId\n  createdAt\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '334836c304b8a07b037e1b6b36cd0e14';
export default node;
