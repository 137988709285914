/* tslint:disable */
/* eslint-disable */
/* @relayHash 4903dd9ba51ccb4220f2d5157a6734f9 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type UserOrderField = "CREATED_AT" | "EMAIL" | "%future added value";
export type UserOrder = {
    direction: OrderDirection;
    field: UserOrderField;
};
export type UserList_QueryVariables = {
    count?: number | null;
    cursor?: string | null;
    orderBy?: UserOrder | null;
};
export type UserList_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserListTableCard_root">;
};
export type UserList_Query = {
    readonly response: UserList_QueryResponse;
    readonly variables: UserList_QueryVariables;
};



/*
query UserList_Query(
  $count: Int
  $cursor: String
  $orderBy: UserOrder
) {
  ...UserListTableCard_root
}

fragment UserListTableCard_root on Query {
  viewer {
    id
    role
    email
  }
  users(first: $count, after: $cursor, orderBy: $orderBy) {
    edges {
      node {
        id
        email
        role
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "UserOrder",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserList_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "UserListTableCard_root",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserList_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "users",
        "args": (v4/*: any*/),
        "handle": "connection",
        "key": "UserListTableCard_users",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserList_Query",
    "id": null,
    "text": "query UserList_Query(\n  $count: Int\n  $cursor: String\n  $orderBy: UserOrder\n) {\n  ...UserListTableCard_root\n}\n\nfragment UserListTableCard_root on Query {\n  viewer {\n    id\n    role\n    email\n  }\n  users(first: $count, after: $cursor, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        email\n        role\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd5125f772ff72b36db3a7e07d86a7174';
export default node;
