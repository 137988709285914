import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { MembershipRoleEditButton_membership } from "../__relay_artifacts__/MembershipRoleEditButton_membership.graphql";
import ModalButton from "../components/ModalButton";
import MembershipRoleEditForm from "./MembershipRoleEditForm";

type Props = {
  membership: MembershipRoleEditButton_membership;
} & ButtonProps;

const MembershipRoleEditButton: React.FC<Props> = ({
  children,
  membership
}) => (
  <ModalButton
    variant="outlined"
    render={({ close }) => (
      <MembershipRoleEditForm
        membership={membership}
        onSubmitCompleted={close}
      />
    )}
  >
    {children}
  </ModalButton>
);

export default createFragmentContainer(MembershipRoleEditButton, {
  membership: graphql`
    fragment MembershipRoleEditButton_membership on Membership {
      ...MembershipRoleEditForm_membership
    }
  `
});
