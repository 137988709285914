/* tslint:disable */
/* eslint-disable */
/* @relayHash 4018b5e6275a0f3b68dce218adf71faa */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_QueryVariables = {
    projectId: string;
};
export type ProjectHeader_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_project">;
    } | null;
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_viewer">;
    };
};
export type ProjectHeader_Query = {
    readonly response: ProjectHeader_QueryResponse;
    readonly variables: ProjectHeader_QueryVariables;
};



/*
query ProjectHeader_Query(
  $projectId: ID!
) {
  project(id: $projectId) {
    ...ProjectHeader_project
    id
  }
  viewer {
    ...ProjectHeader_viewer
    id
  }
}

fragment AccountButton_viewer on User {
  id
  email
}

fragment ProjectDrawerMenuList_project on Project {
  id
  name
  viewerCanReadCampaign
  viewerCanAdministrate
}

fragment ProjectDrawerMenuList_viewer on User {
  ...AccountButton_viewer
  role
  projects {
    edges {
      node {
        id
        name
        viewerCanAdministrate
      }
    }
  }
}

fragment ProjectHeader_project on Project {
  ...ProjectSelectionButton_project
  ...ProjectDrawerMenuList_project
}

fragment ProjectHeader_viewer on User {
  id
  ...AccountButton_viewer
  ...ProjectSelectionButton_viewer
  ...ProjectDrawerMenuList_viewer
}

fragment ProjectSelectionButton_project on Project {
  id
  name
}

fragment ProjectSelectionButton_viewer on User {
  role
  projects {
    edges {
      node {
        id
        name
        viewerCanAdministrate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanAdministrate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProjectHeader_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProjectHeader_project",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProjectHeader_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProjectHeader_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanReadCampaign",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "role",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "projects",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Project",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProjectHeader_Query",
    "id": null,
    "text": "query ProjectHeader_Query(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    ...ProjectHeader_project\n    id\n  }\n  viewer {\n    ...ProjectHeader_viewer\n    id\n  }\n}\n\nfragment AccountButton_viewer on User {\n  id\n  email\n}\n\nfragment ProjectDrawerMenuList_project on Project {\n  id\n  name\n  viewerCanReadCampaign\n  viewerCanAdministrate\n}\n\nfragment ProjectDrawerMenuList_viewer on User {\n  ...AccountButton_viewer\n  role\n  projects {\n    edges {\n      node {\n        id\n        name\n        viewerCanAdministrate\n      }\n    }\n  }\n}\n\nfragment ProjectHeader_project on Project {\n  ...ProjectSelectionButton_project\n  ...ProjectDrawerMenuList_project\n}\n\nfragment ProjectHeader_viewer on User {\n  id\n  ...AccountButton_viewer\n  ...ProjectSelectionButton_viewer\n  ...ProjectDrawerMenuList_viewer\n}\n\nfragment ProjectSelectionButton_project on Project {\n  id\n  name\n}\n\nfragment ProjectSelectionButton_viewer on User {\n  role\n  projects {\n    edges {\n      node {\n        id\n        name\n        viewerCanAdministrate\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '637bc0d688ed29d191fbd10c28277f1f';
export default node;
