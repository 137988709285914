import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";

import ModalButton from "../components/ModalButton";
import MembershipsCreateForm from "./MembershipCreateForm";

type Props = ButtonProps;

const MembershipsCreateButton: React.FC<Props> = ({ children }) => (
  <ModalButton
    render={({ close }) => <MembershipsCreateForm onSubmitCompleted={close} />}
  >
    {children}
  </ModalButton>
);

export default MembershipsCreateButton;
