import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendPromotionEditButton_nendPromotion } from "../__relay_artifacts__/NendPromotionEditButton_nendPromotion.graphql";
import ModalButton from "../components/ModalButton";
import NendPromotionEditForm from "./NendPromotionEditForm";

type Props = {
  promotion: NendPromotionEditButton_nendPromotion;
  variant?: string;
  color?: string;
} & ButtonProps;

const NendPromotionEditButton: React.FC<Props> = ({
  children,
  promotion,
  ...buttonProps
}) => (
  <ModalButton
    {...buttonProps}
    render={({ close }) => (
      <NendPromotionEditForm promotion={promotion} onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);

export default createFragmentContainer(NendPromotionEditButton, {
  nendPromotion: graphql`
    fragment NendPromotionEditButton_nendPromotion on NendPromotion {
      ...NendPromotionEditForm_nendPromotion
    }
  `
});
