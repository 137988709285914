/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectListItem_project = {
    readonly id: string;
    readonly name: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly " $refType": "ProjectListItem_project";
};
export type ProjectListItem_project$data = ProjectListItem_project;
export type ProjectListItem_project$key = {
    readonly " $data"?: ProjectListItem_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectListItem_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectListItem_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '93b38a1d2045d6f9506774fc35b006d2';
export default node;
