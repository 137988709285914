/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionEditForm_nendPromotion = {
    readonly id: string;
    readonly title: string;
    readonly nendPromotionId: string | null;
    readonly fee: number;
    readonly " $refType": "NendPromotionEditForm_nendPromotion";
};
export type NendPromotionEditForm_nendPromotion$data = NendPromotionEditForm_nendPromotion;
export type NendPromotionEditForm_nendPromotion$key = {
    readonly " $data"?: NendPromotionEditForm_nendPromotion$data;
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditForm_nendPromotion">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendPromotionEditForm_nendPromotion",
  "type": "NendPromotion",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nendPromotionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fee",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'b04afd6bc43e5bbab6dbd94e88072ec2';
export default node;
