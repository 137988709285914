import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { useParams } from "react-router";

import { ProjectHeader_Query } from "../__relay_artifacts__/ProjectHeader_Query.graphql";
import ProjectHeader from "../components/ProjectHeader";
import Environment from "../lib/Environment";

const query = graphql`
  query ProjectHeader_Query($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectHeader_project
    }
    viewer {
      ...ProjectHeader_viewer
    }
  }
`;

const Container: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <QueryRenderer<ProjectHeader_Query>
      environment={Environment}
      query={query}
      cacheConfig={{ force: true }}
      variables={{ projectId }}
      render={({ error, props }) => {
        if (error) return <div>{error.message}</div>;
        if (!props) return <div>Loading</div>;
        return <ProjectHeader project={props.project!} viewer={props.viewer} />;
      }}
    />
  );
};

export default Container;
