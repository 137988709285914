import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import React from "react";
import * as yup from "yup";

export interface FormValues {
  email: string;
  password: string;
}

type Props = {
  title: string;
} & FormikProps<FormValues>;

export const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required()
});

const UserForm: React.FC<Props> = ({ title, isSubmitting }) => (
  <Form>
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikTextField
              name="email"
              label="Email"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              name="password"
              label="Password"
              type="password"
              margin="normal"
              autoComplete="current-password"
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          fullWidth
          type="submit"
          variant="contained"
          disabled={isSubmitting}
        >
          保存する
        </Button>
      </CardActions>
    </Card>
  </Form>
);

export default UserForm;
