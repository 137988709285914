import "@firebase/auth";

import firebase from "@firebase/app";
import { ListItemIcon } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import * as React from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

const LogoutButton: React.FC = () => {
  const [state, logout] = useAsyncFn(async () => {
    if (!firebase.auth) {
      throw new Error("firebase.auth is not set");
    }

    return firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.replace(`/signin`);
      });
  });

  return (
    <ListItem button color="inherit" onClick={logout} disabled={state.loading}>
      <ListItemIcon>
        <PowerSettingsNewIcon />
      </ListItemIcon>
      <ListItemText primary="ログアウト" />
    </ListItem>
  );
};

export default LogoutButton;
