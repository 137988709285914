import { useSnackbar } from "notistack";
import * as React from "react";

import ConfirmButton from "../components/atoms/ConfirmButton";
import { useRelayEnvironment } from "../lib/relay-hooks";
import DeleteNendCampaignGroupMutation from "../mutations/DeleteNendCampaignGroupMutation";

type Props = {
  promotionId: string;
  campaignGroupId: string;
};

export const NendCampaignGroupDeleteButton: React.FC<Props> = ({
  promotionId,
  campaignGroupId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();

  const handleDelete = React.useCallback(async () => {
    try {
      const {
        deleteNendCampaignGroup
      } = await DeleteNendCampaignGroupMutation.commit(
        environment,
        promotionId,
        campaignGroupId
      );

      const id =
        deleteNendCampaignGroup &&
        deleteNendCampaignGroup.deletedCampaignGroupId;
      if (!id) throw new Error("assertion failed");

      enqueueSnackbar(`キャンペーングループを削除しました。`, {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [campaignGroupId, enqueueSnackbar, environment, promotionId]);

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このキャンペーングループを削除しますか？"
      confirmContent=""
      onAgree={async changeModal => {
        await handleDelete();
        changeModal(false);
      }}
    >
      削除
    </ConfirmButton>
  );
};
