/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionEditButton_nendPromotion = {
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditForm_nendPromotion">;
    readonly " $refType": "NendPromotionEditButton_nendPromotion";
};
export type NendPromotionEditButton_nendPromotion$data = NendPromotionEditButton_nendPromotion;
export type NendPromotionEditButton_nendPromotion$key = {
    readonly " $data"?: NendPromotionEditButton_nendPromotion$data;
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditButton_nendPromotion">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendPromotionEditButton_nendPromotion",
  "type": "NendPromotion",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NendPromotionEditForm_nendPromotion",
      "args": null
    }
  ]
};
(node as any).hash = '530897a648de12500566888645d66a2c';
export default node;
