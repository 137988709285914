import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { FormikDateField, FormikMultipleSelectField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { PromotionReportFormContainer_project } from "../../__relay_artifacts__/PromotionReportFormContainer_project.graphql";
import { AdvertiserReportList } from "./AdvertiserReportList";

export type FormValues = yup.InferType<typeof validationSchema>;

type Props = {
  isFormCached: boolean;
  project: PromotionReportFormContainer_project;
} & FormikProps<FormValues>;

export const validationSchema = yup.object().shape({
  promotionIds: yup
    .array()
    .of(yup.string())
    .required(),
  periodSince: yup.string().required(),
  periodTill: yup.string().required()
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  formControl: {
    minWidth: 150
  },
  textField: {
    margin: 0
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px"
  }
}));

const PromotionReportForm: React.FC<Props> = ({
  isFormCached,
  project,
  values,
  isSubmitting
}) => {
  const classes = useStyles();
  const [haveBeenSubmitted, setHaveBeenSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (isSubmitting === false) return;
    setHaveBeenSubmitted(true);
  }, [isSubmitting]);

  const promotionNodes = React.useMemo(() => {
    const promotionEdges =
      project.nendPromotions && project.nendPromotions.edges;
    if (!promotionEdges) throw new Error("assertion failed");
    return promotionEdges.map(promotionEdge => {
      const node = promotionEdge && promotionEdge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [project.nendPromotions]);

  const promotionIdsOptions = React.useMemo(
    () =>
      promotionNodes.map(promotionNode => ({
        value: promotionNode.id,
        label: promotionNode.title as string
      })),
    [promotionNodes]
  );

  return (
    <div className={classes.root}>
      <Form>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikMultipleSelectField
                  fullWidth
                  name="promotionIds"
                  label="プロモーションを選択"
                  formControlClassName={classes.formControl}
                  options={promotionIdsOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodSince"
                  label="開始日"
                  format="M月 d日"
                  maxDate={values.periodTill}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDateField
                  fullWidth
                  name="periodTill"
                  label="終了日"
                  format="M月 d日"
                  minDate={values.periodSince}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              disabled={isSubmitting}
            >
              表示
            </Button>
          </CardActions>
        </Card>
      </Form>
      {isSubmitting ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress variant="indeterminate" size="60px" />
        </div>
      ) : (
        // NOTE: Do not disply the list if form isn't cached and has never been submitted
        (isFormCached || haveBeenSubmitted) && (
          <AdvertiserReportList projectRef={project} />
        )
      )}
    </div>
  );
};

export default PromotionReportForm;
