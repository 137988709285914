/* tslint:disable */
/* eslint-disable */
/* @relayHash 7cca8b1c4e2b4fe6e8dffd0f1bc466ac */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateNendPromotionInput = {
    id: string;
    title: string;
    fee: number;
    nendPromotionId?: string | null;
    clientMutationId?: string | null;
};
export type UpdateNendPromotionMutationVariables = {
    input: UpdateNendPromotionInput;
};
export type UpdateNendPromotionMutationResponse = {
    readonly updateNendPromotion: {
        readonly nendPromotion: {
            readonly id: string;
            readonly title: string;
            readonly createdAt: string;
            readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditButton_nendPromotion">;
        } | null;
    } | null;
};
export type UpdateNendPromotionMutation = {
    readonly response: UpdateNendPromotionMutationResponse;
    readonly variables: UpdateNendPromotionMutationVariables;
};



/*
mutation UpdateNendPromotionMutation(
  $input: UpdateNendPromotionInput!
) {
  updateNendPromotion(input: $input) {
    nendPromotion {
      id
      title
      createdAt
      ...NendPromotionEditButton_nendPromotion
    }
  }
}

fragment NendPromotionEditButton_nendPromotion on NendPromotion {
  ...NendPromotionEditForm_nendPromotion
}

fragment NendPromotionEditForm_nendPromotion on NendPromotion {
  id
  title
  nendPromotionId
  fee
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateNendPromotionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateNendPromotionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendPromotion",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendPromotionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotion",
            "storageKey": null,
            "args": null,
            "concreteType": "NendPromotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "NendPromotionEditButton_nendPromotion",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateNendPromotionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateNendPromotion",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNendPromotionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotion",
            "storageKey": null,
            "args": null,
            "concreteType": "NendPromotion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "nendPromotionId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fee",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateNendPromotionMutation",
    "id": null,
    "text": "mutation UpdateNendPromotionMutation(\n  $input: UpdateNendPromotionInput!\n) {\n  updateNendPromotion(input: $input) {\n    nendPromotion {\n      id\n      title\n      createdAt\n      ...NendPromotionEditButton_nendPromotion\n    }\n  }\n}\n\nfragment NendPromotionEditButton_nendPromotion on NendPromotion {\n  ...NendPromotionEditForm_nendPromotion\n}\n\nfragment NendPromotionEditForm_nendPromotion on NendPromotion {\n  id\n  title\n  nendPromotionId\n  fee\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ff99c5c68428904731ebd2294bb02da7';
export default node;
