/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdvertiserReportList_project = {
    readonly id: string;
    readonly report: {
        readonly measures: ReadonlyArray<ReadonlyArray<string>>;
    };
    readonly " $refType": "AdvertiserReportList_project";
};
export type AdvertiserReportList_project$data = AdvertiserReportList_project;
export type AdvertiserReportList_project$key = {
    readonly " $data"?: AdvertiserReportList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"AdvertiserReportList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "AdvertiserReportList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "measures",
      "type": "[MeasureInput!]!"
    },
    {
      "kind": "RootArgument",
      "name": "filter",
      "type": "FilterInput!"
    },
    {
      "kind": "RootArgument",
      "name": "orders",
      "type": "[OrderInput!]"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "report",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "measures",
          "variableName": "measures"
        },
        {
          "kind": "Variable",
          "name": "orders",
          "variableName": "orders"
        }
      ],
      "concreteType": "Report",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "measures",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'ae6d7b0b671d91fdb9041c3b38a5cc12';
export default node;
