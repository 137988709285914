import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppsIcon from "@material-ui/icons/Apps";
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";

import LinkListItem from "./atoms/LinkListItem";

const matches = (path: string, re: RegExp) => !!path.match(re);

const AdminDrawerMenuList = () => {
  return (
    <div>
      <List>
        <LinkListItem
          key="home"
          to={`/admin/home`}
          button
          selected={matches(window.location.pathname, /home/)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="ホーム" />
        </LinkListItem>
        <LinkListItem
          key="users"
          to={`/admin/users`}
          button
          selected={matches(window.location.pathname, /users/)}
        >
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="ユーザー" />
        </LinkListItem>
      </List>
      <Divider />
      <List>
        <LinkListItem key="root" to={`/`} button>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="プロジェクト選択" />
        </LinkListItem>
      </List>
    </div>
  );
};

export default AdminDrawerMenuList;
