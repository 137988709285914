import * as React from "react";
import { QueryRenderer, RelayPaginationProp, graphql } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { NendCampaignList_Query } from "../__relay_artifacts__/NendCampaignList_Query.graphql";
import NendCampaignList from "../components/NendCampaignList";
import Environment from "../lib/Environment";

type Props = {
  relay: RelayPaginationProp;
} & RouteComponentProps<{ projectId: string; campaignGroupId: string }>;

const query = graphql`
  query NendCampaignList_Query(
    $projectId: ID!
    $nendCampaignGroupId: ID!
    $first: Int
    $after: String
  ) {
    project(id: $projectId) {
      ...NendCampaignList_project
    }
  }
`;

const NendCampaignlist: React.FC<Props> = ({
  match: {
    params: { projectId, campaignGroupId }
  }
}) => (
  <QueryRenderer<NendCampaignList_Query>
    environment={Environment}
    query={query}
    cacheConfig={{ force: true }}
    variables={{
      projectId,
      nendCampaignGroupId: campaignGroupId,
      first: 25
    }}
    render={({ error, props }) => {
      if (error) return <div>{error.message}</div>;
      if (!props) return <div>Loading</div>;
      return <NendCampaignList project={props.project!} />;
    }}
  />
);

export default NendCampaignlist;
