/* tslint:disable */
/* eslint-disable */
/* @relayHash 9fd9bed2d07cc80afc2af7924581e568 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteNendCampaignInput = {
    campaignId: string;
    clientMutationId?: string | null;
};
export type DeleteNendCampaignMutationVariables = {
    input: DeleteNendCampaignInput;
};
export type DeleteNendCampaignMutationResponse = {
    readonly deleteNendCampaign: {
        readonly deletedCampaignId: string;
    } | null;
};
export type DeleteNendCampaignMutation = {
    readonly response: DeleteNendCampaignMutationResponse;
    readonly variables: DeleteNendCampaignMutationVariables;
};



/*
mutation DeleteNendCampaignMutation(
  $input: DeleteNendCampaignInput!
) {
  deleteNendCampaign(input: $input) {
    deletedCampaignId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteNendCampaignInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteNendCampaign",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteNendCampaignPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedCampaignId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteNendCampaignMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteNendCampaignMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteNendCampaignMutation",
    "id": null,
    "text": "mutation DeleteNendCampaignMutation(\n  $input: DeleteNendCampaignInput!\n) {\n  deleteNendCampaign(input: $input) {\n    deletedCampaignId\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ad338a3356ea86d859f267a2945cf99f';
export default node;
