import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendCampaignGroupEditButton_nendCampaignGroup } from "../__relay_artifacts__/NendCampaignGroupEditButton_nendCampaignGroup.graphql";
import ModalButton from "../components/ModalButton";
import NendCampaignGroupEditForm from "./NendCampaignGroupEditForm";

type Props = {
  campaignGroup: NendCampaignGroupEditButton_nendCampaignGroup;
  variant?: string;
  color?: string;
} & ButtonProps;

const NendCampaignGroupEditButton: React.FC<Props> = ({
  children,
  campaignGroup,
  ...buttonProps
}) => (
  <ModalButton
    {...buttonProps}
    render={({ close }) => (
      <NendCampaignGroupEditForm
        campaignGroup={campaignGroup}
        onSubmitCompleted={close}
      />
    )}
  >
    {children}
  </ModalButton>
);

export default createFragmentContainer(NendCampaignGroupEditButton, {
  nendCampaignGroup: graphql`
    fragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {
      ...NendCampaignGroupEditForm_nendCampaignGroup
    }
  `
});
