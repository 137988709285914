import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import ProjectForm, { FormValues, schema } from "../components/ProjectForm";
import Environment from "../lib/Environment";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateProjectMutation from "../mutations/CreateProjectMutation";

type Props = RouteComponentProps<{ id: string; projectId: string }>;

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  card: {
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },

  cardAction: {
    width: "100%"
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}));

const ProjectCreate: React.FC<Props> = props => {
  const { history } = props;
  const classes = useStyles();
  return (
    <main className={classes.main}>
      <Card className={classes.card}>
        <CardHeader title="プロジェクトの作成" />
        <CardContent>
          <Formik<FormValues>
            initialValues={{ name: "" }}
            validationSchema={schema}
            render={ProjectForm}
            onSubmit={async (values, { setErrors }) => {
              try {
                const { createProject } = await CreateProjectMutation.commit(
                  Environment,
                  values
                );

                const project = createProject && createProject.project;
                if (!project) throw new Error("assertion failed");

                history.push(`/projects/${project.id}`);
              } catch (err) {
                const errors = extractValidationErrors<FormValues>(err);
                if (errors) setErrors(errors);
              }
            }}
          />
        </CardContent>
      </Card>
    </main>
  );
};

export default ProjectCreate;
