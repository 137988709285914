import * as DateFns from "date-fns";
import { Formik, FormikConfig } from "formik";
import * as React from "react";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import useMount from "react-use/lib/useMount";
import { useGlobal } from "reactn";

import { PromotionReportFormContainer_project } from "../../__relay_artifacts__/PromotionReportFormContainer_project.graphql";
import { measures, orders } from "./constants";
import PromotionReportForm, {
  FormValues,
  validationSchema
} from "./PromotionReportForm";

type Props = {
  relay: RelayRefetchProp;
  project: PromotionReportFormContainer_project;
};

const PromotionReportFormContainer: React.FC<Props> = ({ relay, project }) => {
  const [caches, setCaches] = useGlobal("advertiserReportCache");
  const currentCache = caches[project.id];
  const isCached = !!currentCache;
  const initialValues = currentCache || {
    promotionIds: [],
    periodSince: DateFns.startOfMonth(new Date()).toISOString(),
    periodTill: DateFns.startOfDay(new Date()).toISOString()
  };

  const refetch = React.useCallback(
    (values: FormValues, force: boolean, callback?: (err: any) => void) => {
      const variables = {
        projectId: project.id,
        measures,
        orders,
        filter: values
      };
      relay.refetch(variables, undefined, callback, { force });
    },
    [project.id, relay]
  );

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      setCaches({ ...caches, [project.id]: values });
      return new Promise(resolve =>
        // NOTE: ignore relay cache
        refetch(values, true, error => {
          if (!!error) setErrors(error);
          resolve();
        })
      );
    },
    [caches, project.id, refetch, setCaches]
  );

  // NOTE: dispatch `refetch` function on the first rendering if cache.values exists
  useMount(() => {
    if (!currentCache) return;
    // NOTE: use relay cache
    refetch(currentCache, false);
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <PromotionReportForm
          {...formikHelpers}
          isFormCached={isCached}
          project={project}
        />
      )}
    </Formik>
  );
};

export default createRefetchContainer(
  PromotionReportFormContainer,
  {
    project: graphql`
      fragment PromotionReportFormContainer_project on Project {
        id
        nendPromotions {
          edges {
            node {
              id
              title
            }
          }
        }
        ...AdvertiserReportList_project
      }
    `
  },
  graphql`
    query PromotionReportFormContainer_Query(
      $projectId: ID!
      $measures: [MeasureInput!]!
      $filter: FilterInput!
      $orders: [OrderInput!]
    ) {
      project(id: $projectId) {
        ...PromotionReportFormContainer_project
      }
    }
  `
);
