import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql
} from "react-relay";
import { Link } from "react-router-dom";

import { NendPromotionListTableCard_project } from "../__relay_artifacts__/NendPromotionListTableCard_project.graphql";
import NendPromotionCreateButton from "../containers/NendPromotionCreateButton";
import { NendPromotionDeleteButton } from "../containers/NendPromotionDeleteButton";
import NendPromotionEditButton from "../containers/NendPromotionEditButton";
import TableCell from "./atoms/TableCell";

type Props = {
  relay: RelayPaginationProp;
  project: NendPromotionListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  table: {
    minWidth: 700
  },
  tableWrapper: {
    overflowX: "auto"
  },
  title: {
    flex: "0 0 auto"
  },
  actions: {
    display: "flex"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NendPromotionListTableCard: React.FC<Props> = ({
  relay,
  project: { nendPromotions, id }
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(25);
  const totalCount = nendPromotions.totalCount;

  const promotions = React.useMemo(() => {
    const edges = (nendPromotions && nendPromotions.edges) || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map(edge => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [nendPromotions, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          プロモーション一覧
        </Typography>
        <NendPromotionCreateButton>
          <Icon className={classes.leftIcon}>add</Icon>
          <span>作成する</span>
        </NendPromotionCreateButton>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>利用料</TableCell>
              <TableCell>Nend Promotion ID</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions.map(promotion => (
              <TableRow hover key={promotion.id}>
                <TableCell>{atob(promotion.id)}</TableCell>
                <TableCell>
                  <Link
                    to={`/projects/${id}/promotions/${promotion.id}/campaign_groups`}
                  >
                    {promotion.title || "設定無し"}
                  </Link>
                </TableCell>
                <TableCell>{promotion.fee}</TableCell>
                <TableCell>{promotion.nendPromotionId || "設定無し"}</TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(promotion.createdAt),
                    "MM/dd/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  <NendPromotionEditButton
                    variant="outlined"
                    color="primary"
                    promotion={promotion}
                  >
                    編集
                  </NendPromotionEditButton>
                  <NendPromotionDeleteButton
                    projectId={id}
                    promotionId={promotion.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, () => ({}));
          setPage(page);
        }}
        onChangeRowsPerPage={event => {
          setPerPage(parseInt(event.target.value));
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  NendPromotionListTableCard,
  {
    project: graphql`
      fragment NendPromotionListTableCard_project on Project {
        id
        nendPromotions(first: $first, after: $after)
          @connection(
            key: "NendPromotionListTableCard_nendPromotions"
            filters: []
          ) {
          edges {
            node {
              id
              title
              nendPromotionId
              fee
              createdAt
              ...NendPromotionEditButton_nendPromotion
            }
          }
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    `
  },
  {
    getConnectionFromProps(props) {
      return props.project.nendPromotions as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.project.id,
        first: count,
        after: cursor
      };
    },
    query: graphql`
      query NendPromotionListTableCard_Query(
        $projectId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...NendPromotionListTableCard_project
        }
      }
    `
  }
);
