import CssBaseline from "@material-ui/core/CssBaseline";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

import AdminHeader from "../containers/AdminHeader";
import ProjectHeader from "../containers/ProjectHeader";

type Props = {
  mode: "admin" | "project";
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex"
  },
  main: {
    [theme.breakpoints.down("xs")]: {
      width: `100%`
    },
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${240}px)`
    }
  },
  content: {},
  toolbar: theme.mixins.toolbar
}));

export const Layout: React.FC<Props> = ({ children, mode }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      {mode === "project" && <ProjectHeader />}
      {mode === "admin" && <AdminHeader />}
      <main className={classes.main}>
        <div className={classes.toolbar} />
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  );
};
