import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateNendCampaignGroupInput,
  CreateNendCampaignGroupMutation
} from "../__relay_artifacts__/CreateNendCampaignGroupMutation.graphql";

const mutation = graphql`
  mutation CreateNendCampaignGroupMutation(
    $input: CreateNendCampaignGroupInput!
  ) {
    createNendCampaignGroup(input: $input) {
      nendCampaignGroupEdge {
        node {
          ...NendCampaignGroupEditButton_nendCampaignGroup
        }
      }
    }
  }
`;

let clientMutationId = 0;

const commit = (
  environment: Environment,
  input: CreateNendCampaignGroupInput
) =>
  commitMutation<CreateNendCampaignGroupMutation>(environment, {
    mutation,
    variables: {
      input: {
        clientMutationId: `${clientMutationId++}`,
        ...input
      }
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.nendPromotionId,
        connectionInfo: [
          {
            key: "NendCampaignGroupListTableCard_nendCampaignGroups",
            rangeBehavior: "prepend"
          }
        ],
        edgeName: "nendCampaignGroupEdge"
      }
    ]
  });

export default { commit };
