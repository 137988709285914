import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer } from "react-relay";
import { useParams } from "react-router";

import NendCampaignForm, {
  FormValues,
  schema
} from "../components/NendCampaignForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateNendCampaignMutation from "../mutations/CreateNendCampaignMutation";

type Props = {
  relay: RelayProp;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
  nendCampaignId: null
};

const NendCampaignCreateForm: React.FC<Props> = ({
  relay,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { campaignGroupId } = useParams<{ campaignGroupId: string }>();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async ({ title, nendCampaignId }, { setErrors }) => {
      try {
        if (!title) return;
        const { createNendCampaign } = await CreateNendCampaignMutation.commit(
          // @ts-ignore
          relay.environment,
          {
            nendCampaignGroupId: campaignGroupId,
            title,
            nendCampaignId
          }
        );

        const campaignEdge =
          createNendCampaign && createNendCampaign.nendCampaignEdge;
        if (!campaignEdge) throw new Error("assertion failed");

        enqueueSnackbar("キャンペーンを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, campaignGroupId, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendCampaignForm {...formikHelpers} title="キャンペーンの作成" />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendCampaignCreateForm, {});
