import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateUserInput,
  CreateUserMutation
} from "../__relay_artifacts__/CreateUserMutation.graphql";

const mutation = graphql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      userEdge {
        node {
          id
          email
          role
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateUserInput) =>
  commitMutation<CreateUserMutation>(environment, {
    mutation,
    variables: {
      input
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: "client:root",
        connectionInfo: [
          {
            key: "UserListTableCard_users",
            rangeBehavior: "prepend"
          }
        ],
        edgeName: "userEdge"
      }
    ]
  });

export default { commit };
