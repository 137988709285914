import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer } from "react-relay";

import UserForm, { FormValues, schema } from "../components/UserForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateUserMutation from "../mutations/CreateUserMutation";

type Props = {
  relay: RelayProp;
  title: string;
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  password: ""
};

const UserCreateForm: React.FC<Props> = ({
  relay,
  title,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createUser } = await CreateUserMutation.commit(
          // @ts-ignore
          relay.environment,
          values
        );

        const userEdge = createUser && createUser.userEdge;
        if (!userEdge) throw new Error("assertion failed");

        enqueueSnackbar("ユーザーを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => <UserForm {...formikHelpers} title={title} />}
    </Formik>
  );
};

export default createFragmentContainer(UserCreateForm, {});
