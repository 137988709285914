import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer } from "react-relay";
import { useParams } from "react-router";

import NendPromotionForm, {
  FormValues,
  schema
} from "../components/NendPromotionForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateNendPromotionMutation from "../mutations/CreateNendPromotionMutation";

type Props = {
  relay: RelayProp;
  onSubmitCompleted: () => void;
};

const initialValues: FormValues = {
  title: "",
  nendPromotionId: null,
  fee: 1.0
};

const NendPromotionCreateForm: React.FC<Props> = ({
  relay,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const {
          createNendPromotion
          // @ts-ignore
        } = await CreateNendPromotionMutation.commit(relay.environment, {
          projectId,
          ...values
        });

        const promotionEdge =
          createNendPromotion && createNendPromotion.nendPromotionEdge;
        if (!promotionEdge) throw new Error("assertion failed");

        enqueueSnackbar("プロモーションを作成しました", { variant: "success" });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, projectId, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => (
        <NendPromotionForm {...formikHelpers} title="プロモーションの作成" />
      )}
    </Formik>
  );
};

export default createFragmentContainer(NendPromotionCreateForm, {});
