import { ButtonProps } from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import * as React from "react";

import ConfirmButton from "../components/atoms/ConfirmButton";
import Environment from "../lib/Environment";
import DeleteUserMutation from "../mutations/DeleteUserMutation";

type Props = {
  userId: string;
} & ButtonProps;

const UserDeleteButton: React.FC<Props> = ({ children, userId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = React.useCallback(
    async (userId: string) => {
      try {
        const { deleteUser } = await DeleteUserMutation.commit(
          Environment,
          userId
        );

        const id = deleteUser && deleteUser.deletedUserId;
        if (!id) throw new Error("assertion failed");

        enqueueSnackbar(`ユーザーを削除しました。`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <ConfirmButton
      onAgree={changeModal => {
        onDelete(userId).then(() => {
          changeModal(false);
        });
      }}
      variant="outlined"
      color="inherit"
      confirmTitle="このユーザーを削除しますか？"
      confirmContent=""
    >
      {children}
    </ConfirmButton>
  );
};

export default UserDeleteButton;
