/* tslint:disable */
/* eslint-disable */
/* @relayHash 66fc734a1ca691731767dfdd089b7565 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateNendCampaignGroupInput = {
    nendPromotionId: string;
    title: string;
    nendCampaignGroupId?: string | null;
    clientMutationId?: string | null;
};
export type CreateNendCampaignGroupMutationVariables = {
    input: CreateNendCampaignGroupInput;
};
export type CreateNendCampaignGroupMutationResponse = {
    readonly createNendCampaignGroup: {
        readonly nendCampaignGroupEdge: {
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupEditButton_nendCampaignGroup">;
            } | null;
        } | null;
    } | null;
};
export type CreateNendCampaignGroupMutation = {
    readonly response: CreateNendCampaignGroupMutationResponse;
    readonly variables: CreateNendCampaignGroupMutationVariables;
};



/*
mutation CreateNendCampaignGroupMutation(
  $input: CreateNendCampaignGroupInput!
) {
  createNendCampaignGroup(input: $input) {
    nendCampaignGroupEdge {
      node {
        ...NendCampaignGroupEditButton_nendCampaignGroup
        id
      }
    }
  }
}

fragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {
  ...NendCampaignGroupEditForm_nendCampaignGroup
}

fragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {
  id
  title
  nendCampaignGroupId
  createdAt
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateNendCampaignGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateNendCampaignGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendCampaignGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendCampaignGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignGroupEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignGroupEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendCampaignGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "NendCampaignGroupEditButton_nendCampaignGroup",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateNendCampaignGroupMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendCampaignGroup",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendCampaignGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignGroupEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendCampaignGroupEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendCampaignGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "nendCampaignGroupId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateNendCampaignGroupMutation",
    "id": null,
    "text": "mutation CreateNendCampaignGroupMutation(\n  $input: CreateNendCampaignGroupInput!\n) {\n  createNendCampaignGroup(input: $input) {\n    nendCampaignGroupEdge {\n      node {\n        ...NendCampaignGroupEditButton_nendCampaignGroup\n        id\n      }\n    }\n  }\n}\n\nfragment NendCampaignGroupEditButton_nendCampaignGroup on NendCampaignGroup {\n  ...NendCampaignGroupEditForm_nendCampaignGroup\n}\n\nfragment NendCampaignGroupEditForm_nendCampaignGroup on NendCampaignGroup {\n  id\n  title\n  nendCampaignGroupId\n  createdAt\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '07f011e0faab81615b29bb8d99c6c543';
export default node;
