/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PromotionReportFormContainer_project = {
    readonly id: string;
    readonly nendPromotions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
            } | null;
        } | null> | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"AdvertiserReportList_project">;
    readonly " $refType": "PromotionReportFormContainer_project";
};
export type PromotionReportFormContainer_project$data = PromotionReportFormContainer_project;
export type PromotionReportFormContainer_project$key = {
    readonly " $data"?: PromotionReportFormContainer_project$data;
    readonly " $fragmentRefs": FragmentRefs<"PromotionReportFormContainer_project">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PromotionReportFormContainer_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nendPromotions",
      "storageKey": null,
      "args": null,
      "concreteType": "NendPromotionConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "NendPromotionEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "NendPromotion",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AdvertiserReportList_project",
      "args": null
    }
  ]
};
})();
(node as any).hash = '414d31e3d6042e4149575dbdf9717bf7';
export default node;
