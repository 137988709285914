import "@firebase/auth";

import firebase from "@firebase/app";
import * as React from "react";
import useMount from "react-use/lib/useMount";

import { FIREBASE_ID_TOKEN } from "./lib/constants";
import { Routes } from "./routes";

export const App: React.FC = () => {
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useMount(() => {
    if (firebase.auth === undefined) return;
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        setIsSignedIn(false);
        setIsLoading(false);
        return;
      }
      user.getIdToken(true).then(idToken => {
        localStorage.setItem(FIREBASE_ID_TOKEN, idToken);
        setIsSignedIn(true);
        setIsLoading(false);
      });
    });
  });

  return isLoading ? (
    <div>Now Loading...</div>
  ) : (
    <Routes isSignedIn={isSignedIn} />
  );
};
