import * as DateFns from "date-fns";
import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { RouteComponentProps } from "react-router";

import { AdvertiserReport_Query } from "../../__relay_artifacts__/AdvertiserReport_Query.graphql";
import PageHeader from "../../components/PageHeader";
import Environment from "../../lib/Environment";
import PromotionReportFormContainer from "./PromotionReportFormContainer";

type Props = RouteComponentProps<{ projectId: string }>;

const query = graphql`
  query AdvertiserReport_Query(
    $projectId: ID!
    $measures: [MeasureInput!]!
    $filter: FilterInput!
    $orders: [OrderInput!]
  ) {
    project(id: $projectId) {
      ...PromotionReportFormContainer_project
    }
  }
`;

const AdvertiserReport: React.FC<Props> = ({ match }) => (
  <>
    <PageHeader title="広告主レポート" />
    <QueryRenderer<AdvertiserReport_Query>
      environment={Environment}
      query={query}
      variables={{
        projectId: match.params.projectId,
        measures: [],
        orders: [],
        filter: {
          periodSince: DateFns.startOfMonth(new Date()).toISOString(),
          periodTill: DateFns.startOfDay(new Date()).toISOString(),
          promotionIds: []
        }
      }}
      render={({ error, props }) => {
        if (error) return <div>{error.message}</div>;
        if (!props) return <div>Loading</div>;
        return <PromotionReportFormContainer project={props.project!} />;
      }}
    />
  </>
);

export default AdvertiserReport;
