import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import React from "react";
import { createPaginationContainer, graphql } from "react-relay";

import { MembershipList_project } from "../__relay_artifacts__/MembershipList_project.graphql";
import { MembershipList_viewer } from "../__relay_artifacts__/MembershipList_viewer.graphql";
import MembershipCreateButton from "../containers/MembershipCreateButton";
import MembershipDeleteButton from "../containers/MembershipDeleteButton";
import MembershipRoleEditButton from "../containers/MembershipRoleEditButton";

type Props = {
  project: MembershipList_project;
  viewer: MembershipList_viewer;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  table: {
    minWidth: 700
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    whiteSpace: "nowrap",
    margin: theme.spacing(1)
  },
  title: {
    flex: "0 0 auto"
  },
  actions: {
    display: "flex"
  }
}));

const MembershipList: React.FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();

  const memberships = React.useMemo(() => {
    const edges = project.memberships?.edges || [];
    return edges.map(edge => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return {
        ...node,
        isEditable: project.viewerCanAdministrate && node.user.id !== viewer.id
      };
    });
  }, [project.memberships, project.viewerCanAdministrate, viewer.id]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="subtitle1" color="inherit">
            メンバー一覧
          </Typography>
        </div>
        <div className={classes.actions}>
          {project.viewerCanAdministrate && (
            <MembershipCreateButton className={classes.button}>
              <Icon>add</Icon> 新規作成
            </MembershipCreateButton>
          )}
        </div>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>役割</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberships.map(membership => (
              <TableRow key={membership.id} hover>
                <TableCell>{membership.user.email}</TableCell>
                <TableCell>
                  {membership.isEditable ? (
                    <MembershipRoleEditButton membership={membership}>
                      {membership.role}
                    </MembershipRoleEditButton>
                  ) : (
                    membership.role
                  )}
                </TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(membership.createdAt),
                    "MM/dd/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  {membership.isEditable && (
                    <MembershipDeleteButton
                      membershipId={membership.id}
                      projectId={project.id}
                    >
                      削除
                    </MembershipDeleteButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default createPaginationContainer(
  MembershipList,
  {
    project: graphql`
      fragment MembershipList_project on Project {
        id
        viewerCanAdministrate
        memberships(first: $first, after: $after)
          @connection(key: "MembershipList_memberships", filters: []) {
          totalCount
          edges {
            node {
              id
              role
              createdAt
              user {
                id
                email
              }
              ...MembershipRoleEditButton_membership
            }
          }
        }
      }
    `,
    viewer: graphql`
      fragment MembershipList_viewer on User {
        id
        email
        role
      }
    `
  },
  {
    getConnectionFromProps(props) {
      return props.project.memberships as any;
    },
    getVariables({ projectId }: any, { count, cursor }) {
      return {
        projectId,
        first: count,
        after: cursor
      };
    },
    query: graphql`
      query MembershipList_Query($projectId: ID!, $first: Int, $after: String) {
        viewer {
          ...MembershipList_viewer
        }
        project(id: $projectId) {
          ...MembershipList_project
        }
      }
    `
  }
);
