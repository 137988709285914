import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendPromotionList_project } from "../__relay_artifacts__/NendPromotionList_project.graphql";
import NendPromotionListTableCard from "./NendPromotionListTableCard";
import PageHeader from "./PageHeader";

type Props = {
  project: NendPromotionList_project;
};

const NendPromotionList: React.FC<Props> = ({ project }) => {
  const [selectedTabIndex, selectTabIndex] = useState(0);
  return (
    <>
      <PageHeader
        title="プロモーション"
        tabs={
          <Tabs
            value={selectedTabIndex}
            onChange={(evt, v) => selectTabIndex(v)}
          >
            <Tab label="プロモーション一覧" />
          </Tabs>
        }
      />
      {selectedTabIndex === 0 && (
        <NendPromotionListTableCard project={project} />
      )}
    </>
  );
};

export default createFragmentContainer(NendPromotionList, {
  project: graphql`
    fragment NendPromotionList_project on Project {
      id
      ...NendPromotionListTableCard_project
    }
  `
});
