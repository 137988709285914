import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendCampaignGroupList_project } from "../__relay_artifacts__/NendCampaignGroupList_project.graphql";
import NendCampaignGroupListTableCard from "./NendCampaignGroupListTableCard";
import PageHeader from "./PageHeader";

type Props = {
  project: NendCampaignGroupList_project;
};

const NendCampaignGroupList: React.FC<Props> = ({ project }) => {
  const [selectedTabIndex, selectTabIndex] = useState(0);
  return (
    <>
      <PageHeader
        title="キャンペーングループ"
        tabs={
          <Tabs value={selectedTabIndex} onChange={(_, v) => selectTabIndex(v)}>
            <Tab label="キャンペーングループ一覧" />
          </Tabs>
        }
      />
      {selectedTabIndex === 0 && (
        <NendCampaignGroupListTableCard project={project} />
      )}
    </>
  );
};

export default createFragmentContainer(NendCampaignGroupList, {
  project: graphql`
    fragment NendCampaignGroupList_project on Project {
      ...NendCampaignGroupListTableCard_project
    }
  `
});
