import * as React from "react";
import { QueryRenderer, RelayPaginationProp, graphql } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { NendPromotionList_Query } from "../__relay_artifacts__/NendPromotionList_Query.graphql";
import NendPromotionList from "../components/NendPromotionList";
import Environment from "../lib/Environment";

type Props = {
  relay: RelayPaginationProp;
} & RouteComponentProps<{ projectId: string }>;

const query = graphql`
  query NendPromotionList_Query($projectId: ID!, $first: Int, $after: String) {
    project(id: $projectId) {
      ...NendPromotionList_project
    }
  }
`;

const NendPromotionlist: React.FC<Props> = ({ match }) => {
  const projectId = match.params.projectId;
  return (
    <QueryRenderer<NendPromotionList_Query>
      environment={Environment}
      query={query}
      cacheConfig={{ force: true }}
      variables={{ projectId, first: 25 }}
      render={({ error, props: res }) => {
        if (error) return <div>{error.message}</div>;
        if (!res) return <div>Loading</div>;
        return <NendPromotionList project={res.project!} />;
      }}
    />
  );
};

export default NendPromotionlist;
