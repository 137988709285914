/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignGroupList_project = {
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupListTableCard_project">;
    readonly " $refType": "NendCampaignGroupList_project";
};
export type NendCampaignGroupList_project$data = NendCampaignGroupList_project;
export type NendCampaignGroupList_project$key = {
    readonly " $data"?: NendCampaignGroupList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"NendCampaignGroupList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendCampaignGroupList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NendCampaignGroupListTableCard_project",
      "args": null
    }
  ]
};
(node as any).hash = '2063930e3b66d28cf3f6bcfd809e7a09';
export default node;
