/* tslint:disable */
/* eslint-disable */
/* @relayHash 10643652bcaec3906168b8219af79632 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendCampaignListTableCard_QueryVariables = {
    projectId: string;
    nendCampaignGroupId: string;
    first?: number | null;
    after?: string | null;
};
export type NendCampaignListTableCard_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"NendCampaignListTableCard_project">;
    } | null;
};
export type NendCampaignListTableCard_Query = {
    readonly response: NendCampaignListTableCard_QueryResponse;
    readonly variables: NendCampaignListTableCard_QueryVariables;
};



/*
query NendCampaignListTableCard_Query(
  $projectId: ID!
  $nendCampaignGroupId: ID!
  $first: Int
  $after: String
) {
  project(id: $projectId) {
    ...NendCampaignListTableCard_project
    id
  }
}

fragment NendCampaignEditButton_nendCampaign on NendCampaign {
  ...NendCampaignEditForm_nendCampaign
}

fragment NendCampaignEditForm_nendCampaign on NendCampaign {
  id
  title
  nendCampaignId
  createdAt
}

fragment NendCampaignListTableCard_project on Project {
  id
  nendCampaignGroup(id: $nendCampaignGroupId) {
    id
    nendCampaigns(first: $first, after: $after) {
      edges {
        node {
          id
          title
          createdAt
          nendCampaignId
          ...NendCampaignEditButton_nendCampaign
          __typename
        }
        cursor
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "nendCampaignGroupId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NendCampaignListTableCard_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NendCampaignListTableCard_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NendCampaignListTableCard_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendCampaignGroup",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "nendCampaignGroupId"
              }
            ],
            "concreteType": "NendCampaignGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nendCampaigns",
                "storageKey": null,
                "args": (v3/*: any*/),
                "concreteType": "NendCampaignConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NendCampaignEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NendCampaign",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "nendCampaignId",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasPreviousPage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasNextPage",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startCursor",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endCursor",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "nendCampaigns",
                "args": (v3/*: any*/),
                "handle": "connection",
                "key": "NendCampaignListTableCard_nendCampaigns",
                "filters": []
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NendCampaignListTableCard_Query",
    "id": null,
    "text": "query NendCampaignListTableCard_Query(\n  $projectId: ID!\n  $nendCampaignGroupId: ID!\n  $first: Int\n  $after: String\n) {\n  project(id: $projectId) {\n    ...NendCampaignListTableCard_project\n    id\n  }\n}\n\nfragment NendCampaignEditButton_nendCampaign on NendCampaign {\n  ...NendCampaignEditForm_nendCampaign\n}\n\nfragment NendCampaignEditForm_nendCampaign on NendCampaign {\n  id\n  title\n  nendCampaignId\n  createdAt\n}\n\nfragment NendCampaignListTableCard_project on Project {\n  id\n  nendCampaignGroup(id: $nendCampaignGroupId) {\n    id\n    nendCampaigns(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          title\n          createdAt\n          nendCampaignId\n          ...NendCampaignEditButton_nendCampaign\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        hasPreviousPage\n        hasNextPage\n        startCursor\n        endCursor\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '60039e6acfb6b8ccdfa9cd2614541a99';
export default node;
