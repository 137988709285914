/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectSelectionButton_project = {
    readonly id: string;
    readonly name: string;
    readonly " $refType": "ProjectSelectionButton_project";
};
export type ProjectSelectionButton_project$data = ProjectSelectionButton_project;
export type ProjectSelectionButton_project$key = {
    readonly " $data"?: ProjectSelectionButton_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectSelectionButton_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectSelectionButton_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '69807cd6b1f9f56b0277e8e591aed93d';
export default node;
