import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  makeStyles
} from "@material-ui/core";
import { formatNumber } from "accounting";
import * as DateFns from "date-fns";
import { isEmpty } from "ramda";
import * as React from "react";
import { CSVLink } from "react-csv";
import { graphql } from "react-relay";

import { AdvertiserReportList_project } from "../../__relay_artifacts__/AdvertiserReportList_project.graphql";
import { PromotionReportFormContainer_project } from "../../__relay_artifacts__/PromotionReportFormContainer_project.graphql";
import TableCell from "../../components/atoms/TableCell";
import { useFragment } from "../../lib/relay-hooks";
import { AdvertiserReportAggregate } from "./AdvertiserReportAggregate";

type Props = {
  projectRef: PromotionReportFormContainer_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  reportAggregate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const cellStyles: React.CSSProperties = {
  wordWrap: "break-word",
  whiteSpace: "normal"
};

const formatDate = (date: string) =>
  `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6)}`;

const projectFragment = graphql`
  fragment AdvertiserReportList_project on Project {
    id
    report(measures: $measures, filter: $filter, orders: $orders) {
      measures
    }
  }
`;

export const AdvertiserReportList: React.FC<Props> = ({ projectRef }) => {
  const classes = useStyles();
  const { report } = useFragment<AdvertiserReportList_project>(
    projectFragment,
    projectRef
  );
  console.log(report);
  const measures = React.useMemo(
    () =>
      report.measures.map(
        ([
          promotionId,
          promotionName,
          reportedAt,
          platform,
          imp,
          click,
          ctr,
          cpc,
          cvr,
          cost,
          install,
          cpi
        ]) => ({
          promotionId,
          promotionName,
          reportDate: formatDate(reportedAt),
          platform,
          imp: !isEmpty(imp) ? formatNumber(Number(imp)) : "",
          click: !isEmpty(click) ? formatNumber(Number(click)) : "",
          ctr: !isEmpty(ctr) ? `${parseFloat(ctr).toFixed(2)}%` : "",
          cpc: !isEmpty(cpc) ? formatNumber(Number(cpc)) : cpc,
          cvr: !isEmpty(cvr) ? `${parseFloat(cvr).toFixed(2)}%` : "",
          cost: !isEmpty(cost) ? `${formatNumber(Number(cost))}円` : cost,
          install: !isEmpty(install) ? formatNumber(Number(install)) : "",
          cpi: !isEmpty(cpi) ? `${formatNumber(Number(cpi))}円` : cpi
        })
      ),
    [report]
  );

  const summary = React.useMemo(
    () =>
      report.measures.map(
        ([
          promotionId,
          promotionName,
          reportedAt,
          platform,
          imp,
          click,
          ctr,
          cpc,
          cvr,
          cost,
          install,
          cpi
        ]) => ({
          imp: !isEmpty(imp) ? Number(imp) : 0,
          click: !isEmpty(click) ? Number(click) : 0,
          install: !isEmpty(install) ? Number(install) : 0,
          cost: !isEmpty(cost) ? Number(cost) : 0,
          ctr: "",
          cpi: ""
        })
      ),
    [report]
  );

  const csv = React.useMemo(
    () =>
      report.measures.map(
        ([
          promotionId,
          promotionName,
          reportedAt,
          platform,
          imp,
          click,
          ctr,
          cpc,
          cvr,
          cost,
          install,
          cpi
        ]) => ({
          日付: formatDate(reportedAt),
          プロモーションID: promotionId,
          プロモーション: promotionName,
          OS: platform,
          インプレッション数: !isEmpty(imp) ? formatNumber(Number(imp)) : "",
          クリック数: !isEmpty(click) ? formatNumber(Number(click)) : "",
          インストール数: !isEmpty(install)
            ? formatNumber(Number(install))
            : "",
          COST: !isEmpty(cost) ? formatNumber(Number(cost)) : "",
          CTR: !isEmpty(ctr) ? `${parseFloat(ctr).toFixed(2)}%` : "",
          CPI: !isEmpty(cpi) ? formatNumber(Number(cpi)) : ""
        })
      ),
    [report]
  );

  if (measures.length === 0) {
    return <div className={classes.reportAggregate}>データがありません</div>;
  }

  return (
    <>
      <div className={classes.reportAggregate}>
        <AdvertiserReportAggregate measures={summary} />
      </div>
      <CSVLink
        filename={`nend-ad-report-${DateFns.format(
          new Date(),
          "M/d/yyyy"
        )}.csv`}
        data={csv}
      >
        CSVをダウンロード
      </CSVLink>
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small" style={cellStyles}>
                日付
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                プロモーションID
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                プロモーション
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                OS
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                インプレッション数
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                クリック数
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                インストール数
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                COST
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                CTR
              </TableCell>
              <TableCell size="small" style={cellStyles}>
                CPI
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measures.map((data, index) => (
              <TableRow key={index}>
                <TableCell style={cellStyles} size="small">
                  {data.reportDate}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.promotionId}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.promotionName}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.platform}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.imp}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.click}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.install}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.cost}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.ctr}
                </TableCell>
                <TableCell style={cellStyles} size="small">
                  {data.cpi}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};
