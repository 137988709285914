/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MembershipRoleEditButton_membership = {
    readonly " $fragmentRefs": FragmentRefs<"MembershipRoleEditForm_membership">;
    readonly " $refType": "MembershipRoleEditButton_membership";
};
export type MembershipRoleEditButton_membership$data = MembershipRoleEditButton_membership;
export type MembershipRoleEditButton_membership$key = {
    readonly " $data"?: MembershipRoleEditButton_membership$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipRoleEditButton_membership">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "MembershipRoleEditButton_membership",
  "type": "Membership",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MembershipRoleEditForm_membership",
      "args": null
    }
  ]
};
(node as any).hash = 'af24748cb109e8e65d0062b41e48b1a1';
export default node;
