import * as React from "react";

import ModalButton from "../components/ModalButton";
import NendPromotionCreateForm from "./NendPromotionCreateForm";

const NendPromotionCreateButton: React.FC = ({ children }) => (
  <ModalButton
    render={({ close }) => (
      <NendPromotionCreateForm onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);

export default NendPromotionCreateButton;
