import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = ListItemProps & LinkProps;

const ReferensableLink = React.forwardRef<Link, LinkProps>((props, ref) => (
  <Link {...props} ref={ref} />
));

const LinkListItem = (props: Props) => (
  // @ts-ignore
  <ListItem component={ReferensableLink} {...props} />
);

export default LinkListItem;
