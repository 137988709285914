import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateNendCampaignInput,
  UpdateNendCampaignMutation
} from "../__relay_artifacts__/UpdateNendCampaignMutation.graphql";

const mutation = graphql`
  mutation UpdateNendCampaignMutation($input: UpdateNendCampaignInput!) {
    updateNendCampaign(input: $input) {
      nendCampaign {
        ...NendCampaignEditButton_nendCampaign
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateNendCampaignInput) =>
  commitMutation<UpdateNendCampaignMutation>(environment, {
    mutation,
    variables: {
      input
    }
  });

export default { commit };
