import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateNendCampaignGroupInput,
  UpdateNendCampaignGroupMutation
} from "../__relay_artifacts__/UpdateNendCampaignGroupMutation.graphql";

const mutation = graphql`
  mutation UpdateNendCampaignGroupMutation(
    $input: UpdateNendCampaignGroupInput!
  ) {
    updateNendCampaignGroup(input: $input) {
      nendCampaignGroup {
        ...NendCampaignGroupEditButton_nendCampaignGroup
      }
    }
  }
`;

const commit = (
  environment: Environment,
  input: UpdateNendCampaignGroupInput
) =>
  commitMutation<UpdateNendCampaignGroupMutation>(environment, {
    mutation,
    variables: {
      input
    }
  });

export default { commit };
