import { Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { RelayProp, createFragmentContainer } from "react-relay";
import { useParams } from "react-router";

import MembershipForm, {
  FormValues,
  schema
} from "../components/MembershipForm";
import { extractValidationErrors } from "../lib/mutationUtils";
import CreateMembershipMutation from "../mutations/CreateMembershipMutation";

type Props = {
  relay: RelayProp;
  onSubmitCompleted: () => void;
};

const initialValues = {
  email: "",
  role: "GUEST" as const
};

const MembershipCreateForm: React.FC<Props> = ({
  relay,
  onSubmitCompleted
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();

  const onSubmit = React.useCallback<FormikConfig<FormValues>["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createMembership } = await CreateMembershipMutation.commit(
          // @ts-ignore
          relay.environment,
          { projectId, ...values }
        );

        const membershipEdge =
          createMembership && createMembership.membershipEdge;
        if (!membershipEdge) throw new Error("assertion failed");

        enqueueSnackbar("メンバーを追加しました", {
          variant: "success"
        });
        onSubmitCompleted();
      } catch (err) {
        enqueueSnackbar("メンバーの追加に失敗しました", { variant: "error" });
        const errors = extractValidationErrors<FormValues>(err);
        if (errors) setErrors(errors);
      }
    },
    [enqueueSnackbar, onSubmitCompleted, projectId, relay.environment]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {formikHelpers => <MembershipForm {...formikHelpers} />}
    </Formik>
  );
};

export default createFragmentContainer(MembershipCreateForm, {});
