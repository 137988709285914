import firebase from "@firebase/app";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Modal from "@material-ui/core/Modal";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import React, { useState } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import * as yup from "yup";

import { AccountButton_viewer } from "../__relay_artifacts__/AccountButton_viewer.graphql";
import AccountForm from "./AccountForm";

type Props = {
  viewer: AccountButton_viewer;
};

const validationSchema = yup.object().shape({
  email: yup.string().required()
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {},
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  }
}));

const AccountButton: React.FC<Props> = ({ viewer }) => {
  const classes = useStyles();
  const [modalOpen, openModal] = useState(false);

  const updateAccount = React.useCallback(({ email }: { email: string }) => {
    if (!firebase.auth) throw new Error("firebase.auth is not set");
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) return Promise.reject();
    return currentUser.updateEmail(email);
  }, []);

  return (
    <>
      <ListItem button onClick={() => openModal(true)}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={viewer.email} />
      </ListItem>
      <Modal open={modalOpen} onClose={() => openModal(false)}>
        <div className={classes.paper}>
          <Typography variant="subtitle1">アカウント編集</Typography>
          <Formik
            initialValues={{ email: viewer.email }}
            validationSchema={validationSchema}
            render={AccountForm}
            onSubmit={async (values, { setErrors }) => {
              try {
                await updateAccount(values);
              } catch (err) {
                setErrors(err);
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default createFragmentContainer(AccountButton, {
  viewer: graphql`
    fragment AccountButton_viewer on User {
      id
      email
    }
  `
});
