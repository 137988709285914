import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  UpdateNendPromotionInput,
  UpdateNendPromotionMutation
} from "../__relay_artifacts__/UpdateNendPromotionMutation.graphql";

const mutation = graphql`
  mutation UpdateNendPromotionMutation($input: UpdateNendPromotionInput!) {
    updateNendPromotion(input: $input) {
      nendPromotion {
        id
        title
        createdAt
        ...NendPromotionEditButton_nendPromotion
      }
    }
  }
`;

const commit = (environment: Environment, input: UpdateNendPromotionInput) =>
  commitMutation<UpdateNendPromotionMutation>(environment, {
    mutation,
    variables: {
      input
    }
  });

export default { commit };
