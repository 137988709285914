import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import { DeleteNendPromotionMutation } from "../__relay_artifacts__/DeleteNendPromotionMutation.graphql";

const mutation = graphql`
  mutation DeleteNendPromotionMutation($input: DeleteNendPromotionInput!) {
    deleteNendPromotion(input: $input) {
      deletedPromotionId
    }
  }
`;

const commit = (
  environment: Environment,
  projectId: string,
  promotionId: string
) =>
  commitMutation<DeleteNendPromotionMutation>(environment, {
    mutation,
    variables: {
      input: { promotionId }
    },
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: projectId,
        connectionKeys: [{ key: "NendPromotionListTableCard_nendPromotions" }],
        pathToConnection: ["project", "nendPromotions"],
        deletedIDFieldName: "deletedPromotionId"
      }
    ]
  });

export default { commit };
