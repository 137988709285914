/* tslint:disable */
/* eslint-disable */
/* @relayHash 59f913bd73141210e5b6b7ee8006ed28 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateNendPromotionInput = {
    projectId: string;
    title: string;
    fee: number;
    nendPromotionId?: string | null;
    clientMutationId?: string | null;
};
export type CreateNendPromotionMutationVariables = {
    input: CreateNendPromotionInput;
};
export type CreateNendPromotionMutationResponse = {
    readonly createNendPromotion: {
        readonly nendPromotionEdge: {
            readonly node: {
                readonly id: string;
                readonly createdAt: string;
                readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditButton_nendPromotion">;
            } | null;
        } | null;
    } | null;
};
export type CreateNendPromotionMutation = {
    readonly response: CreateNendPromotionMutationResponse;
    readonly variables: CreateNendPromotionMutationVariables;
};



/*
mutation CreateNendPromotionMutation(
  $input: CreateNendPromotionInput!
) {
  createNendPromotion(input: $input) {
    nendPromotionEdge {
      node {
        id
        createdAt
        ...NendPromotionEditButton_nendPromotion
      }
    }
  }
}

fragment NendPromotionEditButton_nendPromotion on NendPromotion {
  ...NendPromotionEditForm_nendPromotion
}

fragment NendPromotionEditForm_nendPromotion on NendPromotion {
  id
  title
  nendPromotionId
  fee
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateNendPromotionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateNendPromotionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendPromotion",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendPromotionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendPromotionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendPromotion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "NendPromotionEditButton_nendPromotion",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateNendPromotionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createNendPromotion",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNendPromotionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nendPromotionEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "NendPromotionEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "NendPromotion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "nendPromotionId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fee",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateNendPromotionMutation",
    "id": null,
    "text": "mutation CreateNendPromotionMutation(\n  $input: CreateNendPromotionInput!\n) {\n  createNendPromotion(input: $input) {\n    nendPromotionEdge {\n      node {\n        id\n        createdAt\n        ...NendPromotionEditButton_nendPromotion\n      }\n    }\n  }\n}\n\nfragment NendPromotionEditButton_nendPromotion on NendPromotion {\n  ...NendPromotionEditForm_nendPromotion\n}\n\nfragment NendPromotionEditForm_nendPromotion on NendPromotion {\n  id\n  title\n  nendPromotionId\n  fee\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2b80fb7d7fd956a0c7aa4f5a236e6894';
export default node;
