import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  onExecute: React.MouseEventHandler;
};

const FormDialogAlert: React.FC<Props> = ({ open, onClose, onExecute }) => {
  const handleCancelClick = React.useCallback(
    event => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const handleExecuteClick = React.useCallback(
    event => {
      event.stopPropagation();
      onExecute(event);
    },
    [onExecute]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>編集内容を破棄しますか？</DialogTitle>
      <DialogContent>
        <DialogContentText>
          実行を押すと入力された内容が破棄されます。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={handleCancelClick}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleExecuteClick}>
          破棄
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialogAlert;
