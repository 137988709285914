import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql
} from "react-relay";
import { Link } from "react-router-dom";

import { NendCampaignGroupListTableCard_project } from "../__relay_artifacts__/NendCampaignGroupListTableCard_project.graphql";
import NendCampaignGroupCreateButton from "../containers/NendCampaignGroupCreateButton";
import { NendCampaignGroupDeleteButton } from "../containers/NendCampaignGroupDeleteButton";
import NendCampaignGroupEditButton from "../containers/NendCampaignGroupEditButton";
import TableCell from "./atoms/TableCell";

type Props = {
  relay: RelayPaginationProp;
  project: NendCampaignGroupListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  table: {
    minWidth: 700
  },
  tableWrapper: {
    overflowX: "auto"
  },
  title: {
    flex: "0 0 auto"
  },
  actions: {
    display: "flex"
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NendCampaignGroupListTableCard: React.FC<Props> = ({
  relay,
  project: { nendPromotion, id }
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(25);
  const totalCount = nendPromotion?.nendCampaignGroups.totalCount || 0;

  const nendCampaignGroups = React.useMemo(() => {
    const edges = nendPromotion?.nendCampaignGroups.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map(edge => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [nendPromotion, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          キャンペーングループ一覧
        </Typography>
        <NendCampaignGroupCreateButton>
          <Icon className={classes.leftIcon}>add</Icon>
          <span>作成する</span>
        </NendCampaignGroupCreateButton>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>Nend CampaignGroup ID</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nendCampaignGroups.map(nendCampaignGroup => (
              <TableRow hover key={nendCampaignGroup.id}>
                <TableCell>{atob(nendCampaignGroup.id)}</TableCell>
                <TableCell>
                  <Link
                    to={`/projects/${id}/promotions/${
                      nendPromotion!.id
                    }/campaign_groups/${nendCampaignGroup.id}/campaigns`}
                  >
                    {nendCampaignGroup.title || "設定無し"}
                  </Link>
                </TableCell>
                <TableCell>
                  {nendCampaignGroup.nendCampaignGroupId || "設定無し"}
                </TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(nendCampaignGroup.createdAt),
                    "MM/dd/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  <NendCampaignGroupEditButton
                    variant="outlined"
                    color="primary"
                    campaignGroup={nendCampaignGroup}
                  >
                    編集
                  </NendCampaignGroupEditButton>
                  <NendCampaignGroupDeleteButton
                    promotionId={nendPromotion!.id}
                    campaignGroupId={nendCampaignGroup.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, () => ({}));
          setPage(page);
        }}
        onChangeRowsPerPage={event => {
          setPerPage(parseInt(event.target.value));
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  NendCampaignGroupListTableCard,
  {
    project: graphql`
      fragment NendCampaignGroupListTableCard_project on Project {
        id
        nendPromotion(id: $nendPromotionId) {
          id
          nendCampaignGroups(first: $first, after: $after)
            @connection(
              key: "NendCampaignGroupListTableCard_nendCampaignGroups"
              filters: []
            ) {
            edges {
              node {
                id
                title
                createdAt
                nendCampaignGroupId
                ...NendCampaignGroupEditButton_nendCampaignGroup
              }
            }
            totalCount
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
    `
  },
  {
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.project.id,
        promotionId: props.project.nendPromotion?.id,
        first: count,
        after: cursor
      };
    },
    query: graphql`
      query NendCampaignGroupListTableCard_Query(
        $projectId: ID!
        $nendPromotionId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...NendCampaignGroupListTableCard_project
        }
      }
    `
  }
);
