/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionList_project = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionListTableCard_project">;
    readonly " $refType": "NendPromotionList_project";
};
export type NendPromotionList_project$data = NendPromotionList_project;
export type NendPromotionList_project$key = {
    readonly " $data"?: NendPromotionList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendPromotionList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "NendPromotionListTableCard_project",
      "args": null
    }
  ]
};
(node as any).hash = '5dd8a7fc70d1661b2ca084420d9c68f7';
export default node;
