import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminHome from "../pages/admin/Home";
import UserList from "../pages/admin/UserList";
import AdvertiserReport from "../pages/AdvertiserReport";
import Home from "../pages/Home";
import NendCampaignGroupList from "../pages/NendCampaignGroupList";
import NendCampaignList from "../pages/NendCampaignList";
import NendPromotionDetail from "../pages/NendPromotionDetail";
import NendPromotionList from "../pages/NendPromotionList";
import ProjectCreate from "../pages/ProjectCreate";
import ProjectEdit from "../pages/ProjectEdit";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Top from "../pages/Top";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./AuthRoutes";
import { Layout } from "./Layout";
import { ProjectRoot } from "./ProjectRoot";

type Props = {
  isSignedIn: boolean;
};

export const Routes: React.FC<Props> = ({ isSignedIn }) => (
  <Switch>
    <UnauthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/signin"
      component={Signin}
    />
    <UnauthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/signup"
      component={Signup}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      exact
      path="/"
      component={Top}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/projects/create"
      component={ProjectCreate}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/projects/:projectId"
      render={() => (
        <ProjectRoot>
          <Layout mode="project">
            <Switch>
              <Route exact path="/projects/:projectId/home" component={Home} />
              <Route
                exact
                path="/projects/:projectId/promotions"
                component={NendPromotionList}
              />
              <Route
                exact
                path="/projects/:projectId/promotions/:id"
                component={NendPromotionDetail}
              />
              <Route
                exact
                path="/projects/:projectId/promotions/:promotionId/campaign_groups"
                component={NendCampaignGroupList}
              />
              <Route
                exact
                path="/projects/:projectId/promotions/:promotionId/campaign_groups/:campaignGroupId/campaigns"
                component={NendCampaignList}
              />
              <Route
                exact
                path="/projects/:projectId/edit"
                component={ProjectEdit}
              />
              <Route
                exact
                path="/projects/:projectId/advertiser_report"
                component={AdvertiserReport}
              />
            </Switch>
          </Layout>
        </ProjectRoot>
      )}
    />
    <AuthenticatedRoute
      isSignedIn={isSignedIn}
      path="/admin"
      render={() => (
        <Layout mode="admin">
          <Switch>
            <Route exact path="/admin/home" component={AdminHome} />
            <Route exact path="/admin/users" component={UserList} />
            <Redirect from="/" to="/admin/home" />
          </Switch>
        </Layout>
      )}
    />
  </Switch>
);
