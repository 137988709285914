import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

export interface FormValues {
  name: string;
}

type Props = FormikProps<FormValues>;

export const schema = yup.object().shape({
  name: yup.string().required()
});

const ProjectForm: React.FC<Props> = ({ isSubmitting }) => {
  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField name="name" label="名前" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ProjectForm;
