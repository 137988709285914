import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { Link } from "react-router-dom";

type BreadcrumbItem = {
  title: string;
  link: string;
};

type Props = {
  title?: string;
  tabs?: any;
  backTo?: string;
  breadcrumbs?: BreadcrumbItem[];
};

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {},
  toolbar: theme.mixins.toolbar,
  breadcrumbs: {
    "min-height": 40
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
}));

const RouteBreadcrumbs: React.FC<{ items: BreadcrumbItem[] }> = ({ items }) => {
  const lastItem = items.pop();
  if (!lastItem) {
    throw new Error("assertion failed");
  }

  return (
    <Breadcrumbs aria-label="Breadcrumb">
      {items.map(item => (
        <Link key={item.link} color="inherit" to={item.link}>
          {item.title}
        </Link>
      ))}
      <Typography color="textPrimary">{lastItem.title}</Typography>
    </Breadcrumbs>
  );
};

const PageHeader: React.FC<Props> = ({
  title,
  children,
  tabs,
  backTo,
  breadcrumbs
}) => {
  const classes = useStyles();
  return (
    <>
      <AppBar
        position="static"
        elevation={1}
        color="default"
        className={classes.appBar}
      >
        <Toolbar>
          {backTo && (
            <Link to={backTo}>
              <IconButton className={classes.menuButton} aria-label="Menu">
                <MenuIcon />
              </IconButton>
            </Link>
          )}
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          {children}
        </Toolbar>
        {tabs}
      </AppBar>
      {breadcrumbs && (
        <Toolbar className={classes.breadcrumbs}>
          <RouteBreadcrumbs items={breadcrumbs} />
        </Toolbar>
      )}
    </>
  );
};

export default PageHeader;
