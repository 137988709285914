import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateMembershipInput,
  CreateMembershipMutation
} from "../__relay_artifacts__/CreateMembershipMutation.graphql";

const mutation = graphql`
  mutation CreateMembershipMutation($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      membershipEdge {
        node {
          id
          role
          createdAt
          user {
            email
          }
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: CreateMembershipInput) =>
  commitMutation<CreateMembershipMutation>(environment, {
    mutation,
    variables: {
      input
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.projectId,
        connectionInfo: [
          {
            key: "MembershipList_memberships",
            rangeBehavior: "prepend"
          }
        ],
        edgeName: "membershipEdge"
      }
    ]
  });

export default { commit };
