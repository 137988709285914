/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CompactNendPromotionList_project = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"CompactNendPromotionListTableCard_project">;
    readonly " $refType": "CompactNendPromotionList_project";
};
export type CompactNendPromotionList_project$data = CompactNendPromotionList_project;
export type CompactNendPromotionList_project$key = {
    readonly " $data"?: CompactNendPromotionList_project$data;
    readonly " $fragmentRefs": FragmentRefs<"CompactNendPromotionList_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "CompactNendPromotionList_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompactNendPromotionListTableCard_project",
      "args": null
    }
  ]
};
(node as any).hash = '061db5b98531665ce7e1acaa3a9bad5e';
export default node;
