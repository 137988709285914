import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { FormikSelectField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

export interface FormValues {
  role: "ADMIN" | "PUBLISHER" | "ADVERTISER" | "GUEST";
}

type Props = FormikProps<FormValues>;

export const schema = yup.object().shape({
  role: yup
    .string()
    .oneOf(["ADMIN", "PUBLISHER", "ADVERTISER", "GUEST"])
    .required()
});

const useStyles = makeStyles({
  card: {
    maxWidth: 600
  }
});

const roleOptions = [
  { value: "ADMIN", label: "管理者" },
  { value: "PUBLISHER", label: "メディア(運営者)" },
  { value: "ADVERTISER", label: "広告主" },
  { value: "GUEST", label: "ゲスト" }
];

const MembershipForm: React.FC<Props> = ({ isSubmitting }) => {
  const classes = useStyles();
  return (
    <Form>
      <Card className={classes.card}>
        <CardContent>
          <CardHeader title="メンバー役割編集" />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikSelectField
                name="role"
                label="役割"
                required
                fullWidth
                options={roleOptions}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid item xs={12}>
            <Button
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              保存する
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Form>
  );
};

export default MembershipForm;
