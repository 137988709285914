import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { NendCampaignEditButton_nendCampaign } from "../__relay_artifacts__/NendCampaignEditButton_nendCampaign.graphql";
import ModalButton from "../components/ModalButton";
import NendCampaignEditForm from "./NendCampaignEditForm";

type Props = {
  campaign: NendCampaignEditButton_nendCampaign;
  variant?: string;
  color?: string;
} & ButtonProps;

const NendCampaignEditButton: React.FC<Props> = ({
  children,
  campaign,
  ...buttonProps
}) => (
  <ModalButton
    {...buttonProps}
    render={({ close }) => (
      <NendCampaignEditForm campaign={campaign} onSubmitCompleted={close} />
    )}
  >
    {children}
  </ModalButton>
);

export default createFragmentContainer(NendCampaignEditButton, {
  nendCampaign: graphql`
    fragment NendCampaignEditButton_nendCampaign on NendCampaign {
      ...NendCampaignEditForm_nendCampaign
    }
  `
});
