import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

type Props = {
  measures: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1)
  },
  card: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1)
    }
  }
}));

const measureLabel = (field: string) => {
  switch (field) {
    case "imp":
      return "IMP";
    case "click":
      return "CLICK";
    case "install":
      return "INSTALL";
    case "cost":
      return "COST";
    case "ctr":
      return "CTR";
    case "cpi":
      return "CPI";
    default:
      return field;
  }
};

const sumMeasure = (measures: any) => {
  return measures
    .map((obj: any) => {
      const result: Record<string, any> = {};

      Object.keys(obj).forEach((key: any) => {
        switch (key) {
          case "imp":
          case "click":
          case "install":
          case "cost":
            result[key] = Number(obj[key]);
            break;
          default:
            result[key] = 0;
        }
      });
      return result;
    })
    .reduce((sum: any, obj: any) => {
      const result: Record<string, any> = {};
      Object.keys(obj).forEach(key => {
        result[key] = (sum[key] || 0) + obj[key];
      });
      return result;
    }, {});
};

export const AdvertiserReportAggregate: React.FC<Props> = ({ measures }) => {
  const classes = useStyles();
  const measure = sumMeasure(measures);
  return (
    <Grid container className={classes.root} spacing={2}>
      {Object.keys(measure).map(key => {
        let value = measure[key];
        switch (key) {
          case "click":
          case "imp":
          case "install":
            value = parseInt(value || 0, 10).toLocaleString();
            break;
          case "ctr":
            value = ((100 * measure.click) / measure.imp).toFixed(2) + "%";
            break;
          case "cpi":
            value =
              "¥ " +
              (measure.cost / measure.install).toFixed().toLocaleString();
            break;
          case "cost":
            value = "¥ " + parseInt(value || 0, 10).toLocaleString();
            break;
          default:
            return <React.Fragment key={key} />;
        }

        return (
          <Grid key={key} item xs={6} md={2}>
            <Card>
              <CardContent className={classes.card}>
                <Typography component="p">{measureLabel(key)}</Typography>
                <Typography variant="h6">{value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
