import { graphql } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { Environment } from "relay-runtime";

import {
  CreateNendPromotionInput,
  CreateNendPromotionMutation
} from "../__relay_artifacts__/CreateNendPromotionMutation.graphql";

const mutation = graphql`
  mutation CreateNendPromotionMutation($input: CreateNendPromotionInput!) {
    createNendPromotion(input: $input) {
      nendPromotionEdge {
        node {
          id
          createdAt
          ...NendPromotionEditButton_nendPromotion
        }
      }
    }
  }
`;

let clientMutationId = 0;

const commit = (environment: Environment, input: CreateNendPromotionInput) =>
  commitMutation<CreateNendPromotionMutation>(environment, {
    mutation,
    variables: {
      input: {
        clientMutationId: `${clientMutationId++}`,
        ...input
      }
    },
    configs: [
      {
        type: "RANGE_ADD",
        parentID: input.projectId,
        connectionInfo: [
          {
            key: "NendPromotionListTableCard_nendPromotions",
            rangeBehavior: "prepend"
          }
        ],
        edgeName: "nendPromotionEdge"
      }
    ]
  });

export default { commit };
