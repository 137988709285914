import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { CompactNendPromotionList_project } from "../../__relay_artifacts__/CompactNendPromotionList_project.graphql";
import CompactNendPromotionListTableCard from "./CompactNendPromotionListTableCard";

type Props = {
  project: CompactNendPromotionList_project;
};

const CompactNendPromotionList: React.FC<Props> = ({ project }) => (
  <CompactNendPromotionListTableCard project={project} />
);

export default createFragmentContainer(CompactNendPromotionList, {
  project: graphql`
    fragment CompactNendPromotionList_project on Project {
      id
      ...CompactNendPromotionListTableCard_project
    }
  `
});
