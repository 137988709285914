import { useSnackbar } from "notistack";
import * as React from "react";

import ConfirmButton from "../components/atoms/ConfirmButton";
import { useRelayEnvironment } from "../lib/relay-hooks";
import DeleteNendPromotionMutation from "../mutations/DeleteNendPromotionMutation";

type Props = {
  projectId: string;
  promotionId: string;
};

export const NendPromotionDeleteButton: React.FC<Props> = ({
  projectId,
  promotionId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();

  const handleDelete = React.useCallback(
    async (promotionId: string) => {
      try {
        const {
          deleteNendPromotion
        } = await DeleteNendPromotionMutation.commit(
          environment,
          projectId,
          promotionId
        );

        const id =
          deleteNendPromotion && deleteNendPromotion.deletedPromotionId;
        if (!id) throw new Error("assertion failed");

        enqueueSnackbar(`プロモーションを削除しました。`, {
          variant: "success"
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, environment, projectId]
  );

  return (
    <ConfirmButton
      variant="outlined"
      color="secondary"
      confirmTitle="このプロモーションを削除しますか？"
      confirmContent=""
      onAgree={async changeModal => {
        await handleDelete(promotionId);
        changeModal(false);
      }}
    >
      削除
    </ConfirmButton>
  );
};
