import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ProjectList_viewer } from "../__relay_artifacts__/ProjectList_viewer.graphql";
import ProjectListItem from "./ProjectListItem";

interface Props {
  viewer: ProjectList_viewer;
}

const ProjectList: React.FC<Props> = props => {
  const { edges } = props.viewer.projects;

  if (!edges) {
    throw new Error("assertion failed");
  }

  return edges.length === 0 ? (
    <Typography variant="subtitle1">
      プロジェクトが作成されておりません
    </Typography>
  ) : (
    <List>
      {edges.map(edge => {
        const node = edge && edge.node;
        if (!node) {
          throw new Error("assertion failed");
        }
        return <ProjectListItem key={node.id} number={1} project={node} />;
      })}
    </List>
  );
};

export default createFragmentContainer(ProjectList, {
  viewer: graphql`
    fragment ProjectList_viewer on User {
      email
      projects(
        first: 2147483647 # max GraphQLInt
      ) @connection(key: "ProjectList_projects") {
        edges {
          node {
            id
            ...ProjectListItem_project
          }
        }
      }
    }
  `
});
