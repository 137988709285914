import * as React from "react";

import ModalButton from "../components/ModalButton";
import NendCampaignCreateForm from "./NendCampaignCreateForm";

const NendCampaignCreateButton: React.FC = ({ children }) => (
  <ModalButton
    render={({ close }) => <NendCampaignCreateForm onSubmitCompleted={close} />}
  >
    {children}
  </ModalButton>
);

export default NendCampaignCreateButton;
