/* tslint:disable */
/* eslint-disable */
/* @relayHash 08016b582c56bd4f96f2d89bfa7c2d6c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectEdit_QueryVariables = {
    projectId: string;
};
export type ProjectEdit_QueryResponse = {
    readonly project: {
        readonly " $fragmentRefs": FragmentRefs<"ProjectEditForm_project">;
    } | null;
};
export type ProjectEdit_Query = {
    readonly response: ProjectEdit_QueryResponse;
    readonly variables: ProjectEdit_QueryVariables;
};



/*
query ProjectEdit_Query(
  $projectId: ID!
) {
  project(id: $projectId) {
    ...ProjectEditForm_project
    id
  }
}

fragment ProjectEditForm_project on Project {
  name
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProjectEdit_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ProjectEditForm_project",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProjectEdit_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProjectEdit_Query",
    "id": null,
    "text": "query ProjectEdit_Query(\n  $projectId: ID!\n) {\n  project(id: $projectId) {\n    ...ProjectEditForm_project\n    id\n  }\n}\n\nfragment ProjectEditForm_project on Project {\n  name\n  id\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ebf5d014877fe21e6ae3e5e270bb2c5e';
export default node;
