/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"AccountButton_viewer" | "ProjectSelectionButton_viewer" | "ProjectDrawerMenuList_viewer">;
    readonly " $refType": "ProjectHeader_viewer";
};
export type ProjectHeader_viewer$data = ProjectHeader_viewer;
export type ProjectHeader_viewer$key = {
    readonly " $data"?: ProjectHeader_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_viewer">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectHeader_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AccountButton_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectSelectionButton_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectDrawerMenuList_viewer",
      "args": null
    }
  ]
};
(node as any).hash = '1d1b0999bcad629efe42c02e7c24b8ec';
export default node;
