import React from "react";

import PageHeader from "../../components/PageHeader";

const Home: React.FC = () => (
  <div>
    <PageHeader title="ホーム" />
  </div>
);

export default Home;
