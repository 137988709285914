import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql
} from "react-relay";

import { NendCampaignListTableCard_project } from "../__relay_artifacts__/NendCampaignListTableCard_project.graphql";
import NendCampaignCreateButton from "../containers/NendCampaignCreateButton";
import { NendCampaignDeleteButton } from "../containers/NendCampaignDeleteButton";
import NendCampaignEditButton from "../containers/NendCampaignEditButton";
import TableCell from "./atoms/TableCell";

type Props = {
  relay: RelayPaginationProp;
  project: NendCampaignListTableCard_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3)
  },
  table: {
    minWidth: 700
  },
  tableWrapper: {
    overflowX: "auto"
  },
  title: {
    flex: "0 0 auto"
  },
  actions: {
    display: "flex"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NendCampaignListTableCard: React.FC<Props> = ({
  relay,
  project: { nendCampaignGroup }
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(25);
  const totalCount = nendCampaignGroup?.nendCampaigns.totalCount || 0;

  const nendCampaigns = React.useMemo(() => {
    const edges = nendCampaignGroup?.nendCampaigns.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map(edge => {
      const node = edge && edge.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [nendCampaignGroup, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          キャンペーン一覧
        </Typography>
        <NendCampaignCreateButton>
          <Icon className={classes.leftIcon}>add</Icon>
          <span>作成する</span>
        </NendCampaignCreateButton>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>Nend Campaign ID</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nendCampaigns.map(nendCampaign => (
              <TableRow hover key={nendCampaign.id}>
                <TableCell>{atob(nendCampaign.id)}</TableCell>
                <TableCell>{nendCampaign.title}</TableCell>
                <TableCell>
                  {nendCampaign.nendCampaignId || "設定無し"}
                </TableCell>
                <TableCell>
                  {DateFns.format(
                    DateFns.parseISO(nendCampaign.createdAt),
                    "MM/dd/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  <NendCampaignEditButton
                    variant="outlined"
                    color="primary"
                    campaign={nendCampaign}
                  >
                    編集
                  </NendCampaignEditButton>
                  <NendCampaignDeleteButton
                    campaignGroupId={nendCampaignGroup!.id}
                    campaignId={nendCampaign.id}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, () => ({}));
          setPage(page);
        }}
        onChangeRowsPerPage={event => {
          setPerPage(parseInt(event.target.value));
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  NendCampaignListTableCard,
  {
    project: graphql`
      fragment NendCampaignListTableCard_project on Project {
        id
        nendCampaignGroup(id: $nendCampaignGroupId) {
          id
          nendCampaigns(first: $first, after: $after)
            @connection(
              key: "NendCampaignListTableCard_nendCampaigns"
              filters: []
            ) {
            edges {
              node {
                id
                title
                createdAt
                nendCampaignId
                ...NendCampaignEditButton_nendCampaign
              }
            }
            totalCount
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
    `
  },
  {
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.project.id,
        nendCampaignGroupId: props.project.nendCampaignGroup?.id,
        first: count,
        after: cursor
      };
    },
    query: graphql`
      query NendCampaignListTableCard_Query(
        $projectId: ID!
        $nendCampaignGroupId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...NendCampaignListTableCard_project
        }
      }
    `
  }
);
