/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProjectHeader_project = {
    readonly " $fragmentRefs": FragmentRefs<"ProjectSelectionButton_project" | "ProjectDrawerMenuList_project">;
    readonly " $refType": "ProjectHeader_project";
};
export type ProjectHeader_project$data = ProjectHeader_project;
export type ProjectHeader_project$key = {
    readonly " $data"?: ProjectHeader_project$data;
    readonly " $fragmentRefs": FragmentRefs<"ProjectHeader_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "ProjectHeader_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ProjectSelectionButton_project",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectDrawerMenuList_project",
      "args": null
    }
  ]
};
(node as any).hash = 'e5920924afb2aa4c16593ce6b8a4253f';
export default node;
