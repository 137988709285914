import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Modal from "@material-ui/core/Modal";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ProjectSelectionButton_project } from "../__relay_artifacts__/ProjectSelectionButton_project.graphql";
import { ProjectSelectionButton_viewer } from "../__relay_artifacts__/ProjectSelectionButton_viewer.graphql";
import LinkButton from "./atoms/LinkButton";
import LinkListItem from "./atoms/LinkListItem";

type Props = {
  project: ProjectSelectionButton_project;
  viewer: ProjectSelectionButton_viewer;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  },
  navLink: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#FFFFFF"
    }
  }
}));

const ProjectManageModal: React.FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  const [modalOpen, openModal, closeModal] = useOpenState();

  const { projects } = viewer;
  if (!projects.edges) throw new Error("assertion error");

  return (
    <>
      <Button color="inherit" onClick={openModal}>
        <Typography color="inherit">{project.name}</Typography>
        <ArrowDropDownIcon />
      </Button>
      <Modal open={modalOpen} onClose={closeModal}>
        <div className={classes.paper}>
          <Typography color="inherit" variant="subtitle2">
            プロジェクトを変更する
          </Typography>
          <List className={classes.list}>
            {projects.edges.map(edge => {
              const node = edge && edge.node;
              if (!node) throw new Error("assertion failed");
              return (
                <LinkListItem
                  key={node.id}
                  to={`/projects/${node.id}/home`}
                  button
                >
                  <ListItemText primary={node.name} />
                </LinkListItem>
              );
            })}
          </List>
          {viewer.role === "ADMIN" && (
            <LinkButton
              color="primary"
              className={classes.button}
              to={`/projects/create`}
            >
              新規プロジェクト作成
            </LinkButton>
          )}
        </div>
      </Modal>
    </>
  );
};

export default createFragmentContainer(ProjectManageModal, {
  project: graphql`
    fragment ProjectSelectionButton_project on Project {
      id
      name
    }
  `,
  viewer: graphql`
    fragment ProjectSelectionButton_viewer on User {
      role
      projects {
        edges {
          node {
            id
            name
            viewerCanAdministrate
          }
        }
      }
    }
  `
});
