import { FormikErrors } from "formik";
import {
  ConnectionHandler,
  Environment,
  RecordProxy,
  RecordSourceSelectorProxy
} from "relay-runtime";

export type DeleteMutationParam = {
  environment: Environment;
  itemId: string;
  options: ConnectionUpdaterOptions;
};

export type ConnectionUpdaterOptions = {
  parentId: string;
  connectionName: string;
};

export const sharedDeleteUpdater = (
  itemId: string,
  store: RecordSourceSelectorProxy,
  { parentId, connectionName }: ConnectionUpdaterOptions
) => {
  const proxy = store.get(parentId)!;
  const conn = ConnectionHandler.getConnection(proxy, connectionName);
  if (!conn) {
    return;
  }
  ConnectionHandler.deleteNode(conn, itemId);
};

export const sharedCreateUpdater = (
  store: RecordSourceSelectorProxy,
  itemType: string,
  node: RecordProxy,
  { parentId, connectionName }: ConnectionUpdaterOptions
) => {
  const proxy = store.get(parentId)!;
  const connection = ConnectionHandler.getConnection(proxy, connectionName)!;
  const newEdge = ConnectionHandler.createEdge(
    store,
    connection,
    node,
    `${itemType}Edge`
  );

  ConnectionHandler.insertEdgeBefore(connection, newEdge);
};

type ValidationError = {
  extensions: {
    validationErrors: Array<{ path: string; message: string }>;
  };
};

export const extractValidationErrors = <T>(error: ValidationError) => {
  if (!error.extensions || !error.extensions.validationErrors) return;
  return error.extensions.validationErrors.reduce(
    (acc, curr) => ({ ...acc, [curr.path]: curr.message }),
    {}
  ) as FormikErrors<T>;
};
