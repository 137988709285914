/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NendPromotionDetail_project = {
    readonly nendPromotion: {
        readonly id: string;
        readonly title: string;
        readonly createdAt: string;
        readonly " $fragmentRefs": FragmentRefs<"NendPromotionEditButton_nendPromotion">;
    } | null;
    readonly " $refType": "NendPromotionDetail_project";
};
export type NendPromotionDetail_project$data = NendPromotionDetail_project;
export type NendPromotionDetail_project$key = {
    readonly " $data"?: NendPromotionDetail_project$data;
    readonly " $fragmentRefs": FragmentRefs<"NendPromotionDetail_project">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "NendPromotionDetail_project",
  "type": "Project",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "nendPromotionId",
      "type": "ID!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nendPromotion",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "nendPromotionId"
        }
      ],
      "concreteType": "NendPromotion",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "NendPromotionEditButton_nendPromotion",
          "args": null
        }
      ]
    }
  ]
};
(node as any).hash = 'd04cc219b8964615bbdc3883908d7b26';
export default node;
