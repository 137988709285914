import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import { RouteComponentProps } from "react-router";

import { ProjectEdit_Query } from "../__relay_artifacts__/ProjectEdit_Query.graphql";
import PageHeader from "../components/PageHeader";
import ProjectEditForm from "../containers/ProjectEditForm";
import Environment from "../lib/Environment";

type Props = RouteComponentProps<{ projectId: string }>;

const query = graphql`
  query ProjectEdit_Query($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectEditForm_project
    }
  }
`;

const ProjectEdit: React.FC<Props> = ({ match }) => (
  <QueryRenderer<ProjectEdit_Query>
    environment={Environment}
    query={query}
    cacheConfig={{ force: true }}
    variables={{ projectId: match.params.projectId }}
    render={({ error, props: res }) => {
      if (error) return <div>{error.message}</div>;
      if (!res) return <div>Loading</div>;
      return (
        <>
          <PageHeader title="プロジェクト編集" />
          <ProjectEditForm project={res.project!} />
        </>
      );
    }}
  />
);

export default ProjectEdit;
