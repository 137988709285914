import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

type Props = {
  isSignedIn: boolean;
} & RouteProps;

export const AuthenticatedRoute: React.FC<Props> = ({
  isSignedIn,
  ...routeProps
}) =>
  isSignedIn ? (
    <Route {...routeProps} />
  ) : (
    <Redirect
      to={{ pathname: "/signin", state: { from: routeProps.location } }}
    />
  );

export const UnauthenticatedRoute: React.FC<Props> = ({
  isSignedIn,
  ...routeProps
}) => (!isSignedIn ? <Route {...routeProps} /> : <Redirect to="/" />);
