import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { Link, LinkProps } from "react-router-dom";

import { ProjectListItem_project } from "../__relay_artifacts__/ProjectListItem_project.graphql";

type LinkListItemProps = ListItemProps & LinkProps;

const ReferensableLink = React.forwardRef<Link, LinkProps>((props, ref) => (
  <Link {...props} ref={ref} />
));

const LinkListItem = (props: LinkListItemProps) => {
  // @ts-ignore
  return <ListItem component={ReferensableLink} {...props} />;
};

interface Props {
  number: number;
  project: ProjectListItem_project;
}

const ProjectListItem: React.FC<Props> = props => {
  const { project } = props;
  return (
    <LinkListItem key={project.id} button to={`/projects/${project.id}/home`}>
      <ListItemText primary={project.name} />
    </LinkListItem>
  );
};

export default createFragmentContainer(ProjectListItem, {
  project: graphql`
    fragment ProjectListItem_project on Project {
      id
      name
      createdAt
      updatedAt
    }
  `
});
