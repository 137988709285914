import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { FormikTextField } from "@vrize/vrizead-use";
import { Form, FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

export type FormValues = yup.InferType<typeof schema>;

type Props = {
  title: string;
} & FormikProps<FormValues>;

export const schema = yup.object({
  title: yup.string().required(),
  nendCampaignId: yup.string().nullable()
});

const useStyles = makeStyles({
  root: {},
  card: {
    maxWidth: 600
  }
});

const NendCampaignForm: React.FC<Props> = ({ title, isSubmitting }) => {
  const classes = useStyles();
  return (
    <Form className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <CardHeader title={title} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                required
                type="text"
                name="title"
                label="タイトル"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                type="string"
                name="nendCampaignId"
                label="Nend Campaign ID"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSubmitting}
          >
            保存する
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
};

export default NendCampaignForm;
