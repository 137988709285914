import * as React from "react";
import { QueryRenderer, RelayPaginationProp, graphql } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { NendCampaignGroupList_Query } from "../__relay_artifacts__/NendCampaignGroupList_Query.graphql";
import NendCampaignGroupList from "../components/NendCampaignGroupList";
import Environment from "../lib/Environment";

type Props = {
  relay: RelayPaginationProp;
} & RouteComponentProps<{ projectId: string; promotionId: string }>;

const query = graphql`
  query NendCampaignGroupList_Query(
    $projectId: ID!
    $nendPromotionId: ID!
    $first: Int
    $after: String
  ) {
    project(id: $projectId) {
      ...NendCampaignGroupList_project
    }
  }
`;

const NendCampaignGrouplist: React.FC<Props> = ({
  match: {
    params: { projectId, promotionId }
  }
}) => (
  <QueryRenderer<NendCampaignGroupList_Query>
    environment={Environment}
    query={query}
    cacheConfig={{ force: true }}
    variables={{
      projectId,
      nendPromotionId: promotionId,
      first: 25
    }}
    render={({ error, props: res }) => {
      if (error) return <div>{error.message}</div>;
      if (!res) return <div>Loading</div>;
      return <NendCampaignGroupList project={res.project!} />;
    }}
  />
);

export default NendCampaignGrouplist;
